import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography } from "@material-ui/core";
import MyButton from "../../components/button";
import { Link } from "react-router-dom";
import ConfirmDialog from "../../components/dialog";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import {
  getAssemblies,
  removeAssembly,
  clearAssembliesCache,
} from "../../services/assemblies";
import { initACL } from "../../helpers/acl";
import MyTextField from "../../components/textField";

class Assemblies extends Component {
  state = {
    assembliesAll: [],
    assemblies: [],
    deleteDialog: false,
  };

  componentDidMount = () => {
    getAssemblies()
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ assemblies: data, assembliesAll: data });
      })
      .catch((error) => {});
  };

  handleRemove = (assemblyId) => {
    removeAssembly(assemblyId)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        showInfo(this.props, "مجمع با موفقیت حذف گردید");
        this.setState({
          assemblies: this.state.assemblies.filter(
            (per) => per._id !== assemblyId
          ),
          assembliesAll: this.state.assembliesAll.filter(
            (per) => per._id !== assemblyId
          ),
        });
        clearAssembliesCache()
          .then((data) => {})
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  openDeleteDialog = (assemblyId) =>
    this.setState({ deleteDialog: true, deleteId: assemblyId });

  closeDeleteDialog = () => this.setState({ deleteDialog: false, deleteId: 0 });

  handleDeleteDialogConfirm = () => {
    this.handleRemove(this.state.deleteId);
    this.closeDeleteDialog();
  };

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    let search = event.target.value;
    if (search && search.length > 0) {
      search = search.replace(/ك/gi, "ک");
      search = search.replace(/ي/gi, "ی");
      this.setState({
        assemblies: this.state.assembliesAll.filter((per) =>
          per.name.includes(search)
        ),
      });
    } else this.setState({ assemblies: this.state.assembliesAll });
  };

  render() {
    const { classes } = this.props;
    const { assemblies } = this.state;
    const {
      enableEditButton,
      enableDeleteButton,
      enableAddButton,
      search,
      first_name,
      last_name,
      sending,
      success,
      fail,
    } = this.state;

    return (
      <div /*className={classes.memberScroll}*/>
        <ConfirmDialog
          title="حذف مجمع"
          content="آیا از حذف مطمئن هستید؟"
          open={this.state.deleteDialog}
          handleConfirm={this.handleDeleteDialogConfirm}
          handleCancel={this.closeDeleteDialog}
        />

        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          style={{ marginBottom: "16px" }}
        >
          <Grid item>
            <Link to="/assemblies/new" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                مجمع جدید
              </MyButton>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          style={{ marginBottom: "16px" }}
        >
          <Grid item>
            <MyTextField
              id="search"
              name="search"
              value={search}
              onChange={this.handleSearch}
              classes={classes}
              margin="dense"
              variant="outlined"
              type="text"
              placeholder="جستجو"
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row-reverse"
          spacing={3}
          style={{ marginBottom: "16px" }}
        >
          {assemblies.map((assembly, index) => (
            <Grid key={index} item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className={classes.memberContent}
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item container direction="column" xs={1} spacing={1}>
                    <Grid item>
                      <Link to={`/assemblies/${assembly._id}`}>
                        <img
                          src={require(`../../images/icon_edit.png`)}
                          className={classes.editIcon}
                          title="ویرایش مجمع"
                        />
                      </Link>
                    </Grid>

                    <Grid item>
                      <img
                        src={require(`../../images/icon_delete.png`)}
                        className={classes.editIcon}
                        onClick={() => this.openDeleteDialog(assembly._id)}
                        title="حذف مجمع"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    xs={11}
                    spacing={1}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", color: "DarkBlue" }}
                        >
                          {`${assembly.name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :نماد
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {`${assembly.date}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :تاریخ
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {`${assembly.type}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :نوع مجمع
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {`${assembly.agenda}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :دستور جلسه
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item xs={3}>
                  <img
                    src={require(`../../images/icon_user.png`)}
                    className={classes.memberImage}
                    alt=""
                  />
                        </Grid>*/}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {enableAddButton && (
          <Link to="/templateAnswers/new" style={{ textDecoration: "none" }}>
            <MyButton className={classes.new} variant="contained">
              ثبت مجمع جدید
            </MyButton>
          </Link>
        )}
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(Assemblies)
);
