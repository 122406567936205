import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import LiveContainer from "../containers/live/live";
import BoxTitle from "../components/boxTitle";
import SummaryPWAContainer from "../containers/stats/summaryPWA";
import { unixToTime } from "../helpers/unix";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getStats } from "../services/statsPWA";
import { getServer, getSettings } from "../services/settings";
import { APP_MODE, APP_MODES } from "../constants/settings";
import { plateLetters, getPlatePersianLetter } from "../constants/plateLetters";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../helpers/notification";

class Stats extends Component {
  state = {
    stats: null,
  };

  render() {
    const { classes } = this.props;
    const { capturesCount, targetsCount } = this.state;

    return (
      <Grid
        container
        item
        direction="column"
        spacing={0}
        xs
        /*className={classes.minHeight0}*/
      >
        <Grid item xs={12} /*className={classes.maxHeight100}*/>
          {/*<div className={classes.boxRelative}>
            <div
              className={classNames(classes.boxBorder)}
            >
              <SummaryContainer stats={this.state.stats} />
            </div>
    </div>*/}
          <SummaryPWAContainer stats={this.state.stats} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Stats));
