import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import { Typography, Box } from "@material-ui/core";
import { NavLink, Redirect } from "react-router-dom";
import { getDateTime } from "../services/settings";
import moment from "moment";
import jMoment from "moment-jalaali";
import { APP_MODE, APP_MODES } from "../constants/settings";
import { getUser, removeUser } from "../helpers/user";
import { getAdminACL, getACL } from "../services/admins";
import { verifyACL } from "../helpers/acl";
import { connect } from "react-redux";
import { saveACL } from "../reducers/acl";
import { saveUserACL } from "../reducers/user";
import Hidden from "@material-ui/core/Hidden";
import { IconButton as MUIIconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

const styles = {
  navigation: {
    overflow: "hidden",
  },
  header: {
    background:
      "linear-gradient(0deg, rgba(222,222,222,1) 0%, rgba(255,255,255,1) 100%)",
    //backgroundImage: `url(${require(`../images/header.png`)})`,
    backgroundRepeat: "no-repeat",
    //backgroundPosition: "top center",
    //backgroundSize: "contain",
    backgroundSize: "100% 50px",
    backgroundOrigin: "border-box",
    backgroundAttachment: "fixed",
    borderTop: "2px solid #B3B3B3",
    //borderRadius: 3,
    //boxShadow: "0 3px 5px 2px rgba(0, 0, 128, .3)",
    //color: "white",
    height: 48,
    //padding: "0 30px",
  },
  button: {
    color: "#4b96d7",
    margin: "0px 16px",
  },
  buttonSelected: {
    "& .MuiButton-root": {
      border: "1px solid rgba(255, 255, 255, 0.1)",
      background: "rgba(255, 255, 255, 0.05)",
    },
  },
  menu: {
    color: "#000",
    //margin: "0px 16px",
    padding: "8px 8px",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.05)",
      borderRadius: "8px",
    },
  },
  menuSelected: {
    "& > div": {
      border: "1px solid rgba(255, 255, 255, 0.5)",
      background: "rgba(255, 255, 255, 0.4)",
      borderRadius: "8px",
    },
    "& p": {
      color: "#ADADAD",
    },
  },
  icon: {
    height: "16px",
    marginLeft: 8,
  },
  datetimeGrid: {
    marginTop: "8px",
    marginLeft: "32px",
    color: "#384d82",
  },
  datetime: {},
  userGrid: {
    marginTop: "8px",
    marginRight: "32px",
    color: "white",
  },
  username: {
    color: "#384d82",
    fontSize: 12,
  },
  logout: {
    color: "red",
    cursor: "pointer",
    marginRight: "16px",
    fontSize: 10,
  },
  menuButton: {
    height: 40,
    cursor: "pointer",
    marginRight: "24px",
  },
  list: {
    "& .MuiListItem-root": {
      justifyContent: "flex-end",
    },
  },
  userGridMenu: {
    color: "white",
  },
};

const IconButton = (props) => {
  return (
    <Grid item style={{ margin: "0px 12px" }}>
      {!props.disabled && (
        <NavLink
          to={props.link}
          exact={props.link && props.link.length > 1 ? false : true}
          //activeClassName={props.classes.buttonSelected}
          activeClassName={props.classes.menuSelected}
          //style={{ textDecoration: "none" }}
        >
          {/*<Button variant="text" className={props.classes.button}>
          {props.text}
          <img
            src={require(`../images/${props.icon}.png`)}
            className={props.classes.icon}
            alt=""
          />
        </Button>*/}
          <Grid
            container
            direction="row"
            justify="center"
            className={props.classes.menu}
          >
            <Grid item>
              <Typography variant="body2">{props.text}</Typography>
            </Grid>
            <Grid item>
              <img
                src={require(`../images/${props.icon}.png`)}
                className={props.classes.icon}
                alt=""
              />
            </Grid>
          </Grid>
        </NavLink>
      )}
    </Grid>
  );
};

class Navigation extends Component {
  state = { datetime: "", openMenu: false };

  toggleDrawer = (open) => () => {
    this.setState({
      openMenu: open,
    });
  };

  componentDidMount = async () => {
    moment.locale("fa");

    const user = getUser();
    this.setState({ user });

    const acl = await getACL();
    const ACL_Names = acl.ACL_Names;
    const ACL_Actions = acl.ACL_Actions;
    this.props.saveACL(acl);

    getAdminACL()
      .then((data) => {
        this.props.saveUserACL(data.acl);
        this.setState({
          enableStatsButton: verifyACL(data.acl, ACL_Names.STATS),
          enableStatsPWAButton: verifyACL(data.acl, ACL_Names.STATSPWA),
          enableReportButton: verifyACL(data.acl, ACL_Names.REPORT),
          enableUsersButton: verifyACL(
            data.acl,
            ACL_Names.USER,
            ACL_Actions.VIEW
          ),
          enableInboxButton: verifyACL(
            data.acl,
            ACL_Names.INBOX,
            ACL_Actions.VIEW
          ),
          enableContactButton: verifyACL(
            data.acl,
            ACL_Names.CONTACT,
            ACL_Actions.VIEW
          ),
          enableFAQButton: verifyACL(data.acl, ACL_Names.FAQ, ACL_Actions.VIEW),
          enableDataButton: verifyACL(
            data.acl,
            ACL_Names.DATA,
            ACL_Actions.VIEW
          ),
          enableFilterButton: verifyACL(
            data.acl,
            ACL_Names.FILTER,
            ACL_Actions.VIEW
          ),
          enableAdminsButton: verifyACL(
            data.acl,
            ACL_Names.ADMIN,
            ACL_Actions.VIEW
          ),
        });
      })
      .catch((error) => {});
  };

  handleLogout = () => {
    removeUser();
    this.setState({ logout: true });
  };

  render() {
    const { classes } = this.props;
    const { user } = this.state;
    const {
      enableStatsButton,
      enableStatsPWAButton,
      enableReportButton,
      enableUsersButton,
      enableInboxButton,
      enableContactButton,
      enableFAQButton,
      enableDataButton,
      enableFilterButton,
      enableAdminsButton,
    } = this.state;

    const menus = [
      {
        title: "آمار",
        icon: "icon_settings",
        link: "/",
        enable: this.state.enableStatsButton,
      },
      {
        title: "آمار وب",
        icon: "icon_settings",
        link: "/pwa",
        enable: this.state.enableStatsPWAButton,
      },
      {
        title: "گزارش",
        icon: "icon_settings",
        link: "/report",
        enable: this.state.enableReportButton,
      },
      {
        title: "کاربران",
        icon: "icon_settings",
        link: "/user/search",
        enable: this.state.enableUsersButton,
      },
      {
        title: "صندوق پیام",
        icon: "icon_settings",
        link: "/inbox",
        enable: this.state.enableInboxButton,
      },
      {
        title: "ارتباط با ما",
        icon: "icon_settings",
        link: "/contacts",
        enable: this.state.enableContactButton,
      },
      {
        title: "سوالات متداول",
        icon: "icon_settings",
        link: "/faq",
        enable: this.state.enableFAQButton,
      },
      {
        title: "داده‌ها",
        icon: "icon_settings",
        link: "/data",
        enable: this.state.enableDataButton,
      },
      {
        title: "فیلترها",
        icon: "icon_settings",
        link: "/filter",
        enable: this.state.enableFilterButton,
      },
      {
        title: "مدیران",
        icon: "icon_settings",
        link: "/admins",
        enable: this.state.enableAdminsButton,
      },
    ];

    const sideList = (
      <div>
        <List className={classes.list}>
          {user && (
            <ListItem>
              <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.userGridMenu}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  className={classes.userGridMenu}
                >
                  <Grid item>
                    <Typography
                      component="div"
                      variant="body2"
                      className={classes.username}
                    >
                      <Box>{`کاربر: ${user.name}`}</Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src={require(`../images/icon_user.png`)}
                      className={classes.icon}
                      alt=""
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.logout}
                      onClick={this.handleLogout}
                    >
                      خروج
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          )}
          {menus.map(
            (menu, index) =>
              menu.enable && (
                <ListItem key={index}>
                  <IconButton
                    classes={classes}
                    text={menu.title}
                    icon={menu.icon}
                    link={menu.link}
                    disabled={!menu.enable}
                  />
                </ListItem>
              )
          )}
        </List>
      </div>
    );

    if (this.state.logout === true) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={classes.navigation}>
        <div className={classes.header}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid item container style={{ flex: 1, justifyContent: "center" }}>
              <Hidden smDown>
                <IconButton
                  classes={classes}
                  text="مدیران"
                  icon="icon_settings"
                  link="/admins"
                  disabled={!enableAdminsButton}
                />
                <IconButton
                  classes={classes}
                  text="فیلترها"
                  icon="icon_settings"
                  link="/filter"
                  disabled={!enableFilterButton}
                />
                <IconButton
                  classes={classes}
                  text="داده‌ها"
                  icon="icon_settings"
                  link="/data"
                  disabled={!enableDataButton}
                />
                <IconButton
                  classes={classes}
                  text="سوالات متداول"
                  icon="icon_settings"
                  link="/faq"
                  disabled={!enableFAQButton}
                />
                <IconButton
                  classes={classes}
                  text="ارتباط با ما"
                  icon="icon_settings"
                  link="/contacts"
                  disabled={!enableContactButton}
                />
                <IconButton
                  classes={classes}
                  text="صندوق پیام"
                  icon="icon_settings"
                  link="/inbox"
                  disabled={!enableInboxButton}
                />
                <IconButton
                  classes={classes}
                  text="کاربران"
                  icon="icon_settings"
                  link="/user/search"
                  disabled={!enableUsersButton}
                />
                <IconButton
                  classes={classes}
                  text="گزارش"
                  icon="icon_settings"
                  link="/report"
                  disabled={!enableReportButton}
                />
                <IconButton
                  classes={classes}
                  text="آمار وب"
                  icon="icon_settings"
                  link="/pwa"
                  disabled={!enableStatsPWAButton}
                />
                <IconButton
                  classes={classes}
                  text="آمار"
                  icon="icon_settings"
                  link="/"
                  disabled={!enableStatsButton}
                />
              </Hidden>
            </Grid>

            <Hidden mdUp>
              <Grid container justify="flex-end" style={{ flex: 1 }}>
                <img
                  src={require(`../images/icon_menu.png`)}
                  alt=""
                  onClick={this.toggleDrawer(true)}
                  className={classes.menuButton}
                />
              </Grid>
            </Hidden>
          </div>
        </div>
        <Hidden smDown>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              //spacing={2}
              className={classes.datetimeGrid}
            ></Grid>
            {user && (
              <Grid
                item
                xs
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.userGrid}
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    className={classes.logout}
                    onClick={this.handleLogout}
                  >
                    خروج
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    component="div"
                    variant="body2"
                    className={classes.username}
                  >
                    <Box>{`کاربر: ${user.name}`}</Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <img
                    src={require(`../images/icon_user.png`)}
                    className={classes.icon}
                    alt=""
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Hidden>

        <Drawer
          anchor="right"
          open={this.state.openMenu}
          onClose={this.toggleDrawer(false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    acl: state.acl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveACL: (acl) => {
      dispatch(saveACL(acl));
    },
    saveUserACL: (acl) => {
      dispatch(saveUserACL(acl));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navigation));
