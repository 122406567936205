import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import {
  getMemberImages,
  addMemberImage,
  removeMemberImage,
  getMemberImagePath,
  defaultMemberImage
} from "../../services/members";
import MyButton from "../../components/button";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import ConfirmDialog from "../../components/dialog";
import Dropzone from "../../components/dropzone";

class MemberImages extends Component {
  state = {
    images: []
  };

  componentDidMount = () => {};

  componentDidUpdate = () => {
    if (this.props.member && !this.state.member) {
      const member = this.props.member;
      this.setState({
        edit: true,
        member: member
      });

      const memberId = member.id;
      getMemberImages(memberId)
        .then(data => {
          //if (!data.result) return;
          this.setState({ images: data });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  handleUpload = () => {
    this.setState({
      uploading: true
    });

    const memberId = this.props.member.id;
    const base64 = this.state.file.base64;
    const vector = this.state.vector;

    addMemberImage(memberId, base64, vector)
      .then(data => {
        if (!data.id) return;
        showInfo(this.props, "تصویر با موفقیت بارگذاری شد");
        if (this.state.file) URL.revokeObjectURL(this.state.file.preview);
        this.setState({
          uploading: false,
          file: null,
          images: [data, ...this.state.images]
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleRemove = () => {
    const memberId = this.props.member.id;
    const image = this.state.deleteImage;

    removeMemberImage(memberId, image.id, image.file_name)
      .then(data => {
        showInfo(this.props, "تصویر با موفقیت حذف گردید");
        if (this.state.file) URL.revokeObjectURL(this.state.file.preview);
        this.setState({
          images: this.state.images.filter(img => img.id !== image.id)
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleDefault = imageId => {
    const memberId = this.props.member.id;

    defaultMemberImage(memberId, imageId)
      .then(data => {
        showInfo(this.props, "تصویر پیش فرض با موفقیت تغییر کرد");
        const member = this.state.member;
        member.default_image_id = imageId;
        this.setState({
          member
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onDrop = (vector, preview, base64, facesCount) => {
    if (facesCount == 0) {
      showError(this.props, "هیچ چهره ای در تصویر انتخابی وجود ندارد");
    } else if (facesCount > 1) {
      showError(this.props, "بیشتر از یک چهره در تصویر انتخابی وجود دارد");
    } else {
      this.setState({
        vector: vector,
        file: { preview: preview, base64: base64 }
      });
    }
  };

  componentWillUnmount() {
    if (this.state.file) URL.revokeObjectURL(this.state.file.preview);
  }

  openDeleteDialog = image =>
    this.setState({ deleteDialog: true, deleteImage: image });

  closeDeleteDialog = () =>
    this.setState({ deleteDialog: false, deleteImage: null });

  handleDeleteDialogConfirm = () => {
    this.handleRemove();
    this.closeDeleteDialog();
  };

  render() {
    const { classes, member } = this.props;
    const { images, file, preview } = this.state;
    const { uploading, success, fail } = this.state;

    return (
      <div className={classes.memberScroll}>
        <ConfirmDialog
          title="حذف تصویر"
          content="آیا از حذف مطمئن هستید؟"
          open={this.state.deleteDialog}
          handleConfirm={this.handleDeleteDialogConfirm}
          handleCancel={this.closeDeleteDialog}
        />

        <Grid container direction="column">
          <Grid item>
            <Dropzone hasId={member && member.id} onDrop={this.onDrop} />
          </Grid>
          <Grid item>
            {file && (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <MyButton
                    className={classes.upload}
                    variant="contained"
                    onClick={this.handleUpload}
                    disabled={uploading}
                  >
                    بارگذاری تصویر
                  </MyButton>
                </Grid>
                <Grid item>
                  <img
                    src={file.preview}
                    className={classes.imagePreview}
                    alt=""
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.memberEditImages}
        >
          {images.map((image, index) => (
            <Grid item key={index} xs={6}>
              <div className={classes.relative}>
                <img
                  src={getMemberImagePath(image.person_id, image.file_name)}
                  className={classes.memberEditImage}
                  alt=""
                />
                <img
                  src={require(`../../images/icon_delete_image.png`)}
                  className={classes.deleteIcon}
                  //onClick={() => this.handleRemove(image)}
                  onClick={() => this.openDeleteDialog(image)}
                  title="حذف تصویر"
                />
                <img
                  src={require(`../../images/icon_default_image_${
                    this.state.member.default_image_id === image.id
                      ? "enabled"
                      : "disabled"
                  }.png`)}
                  className={classes.defaultIcon}
                  onClick={() => this.handleDefault(image.id)}
                  title="انتخاب به عنوان تصویر پیش فرض"
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(MemberImages)
);
