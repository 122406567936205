import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import BoxTitle from "../components/boxTitle";
import NewAdminContainer from "../containers/admin/newAdmin";
import { getAdmin } from "../services/admins";

class NewUser extends Component {
  state = { user: null };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      var userId = this.props.match.params.id;
      if (!userId) return;
      if (userId == "new") return;
      getAdmin(userId)
        .then(data => {
          this.setState({ user: data });
          //console.log("user", data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { classes } = this.props;
    const { user } = this.state;

    return <NewAdminContainer user={user} />;
  }
}

export default withStyles(styles)(NewUser);
