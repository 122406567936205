import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import BoxTitle from "../components/boxTitle";
import NewMemberContainer from "../containers/member/newMember";
import MemberImagesContainer from "../containers/member/memberImages";
import { APP_MODE, APP_MODES } from "../constants/settings";
import { getContact } from "../services/contacts";
import { showInfo, showError } from "../helpers/notification";
import MyButton from "../components/button";
import { InputRow } from "../components/inputRow";
import { Link, Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import stylesMembers from "../styles/members";
import { withSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import { addInbox, getInboxImagePath } from "../services/inbox";
import { getFAQ } from "../services/faq";
import Dropzone from "../components/dropzone";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import ReactSelect from "react-select";

const CustomRow = (props) => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      spacing={1}
      style={{ marginTop: "4px" }}
    >
      <Grid item xs={10}>
        {props.customControl}
      </Grid>
      <Grid item xs={2}>
        <Typography align="left" className={props.classes.label}>
          :{props.label}
        </Typography>
      </Grid>
    </Grid>
  );
};

class NewInbox extends Component {
  state = {
    contact: null,
    template_answers: [],
    selected_template_answer: "-1",
    type: "2",
    membership: false,
    notif_all: false,
    title: "پاسخ",
    showMoreSettings: false,
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      var contactId = this.props.match.params.id;
      if (!contactId) return;
      var isFAQ = this.props.location.isFAQ;
      if (isFAQ)
        getFAQ(contactId)
          .then((data) => {
            if (data.forbidden === true) {
              showError(this.props, "عملیات غیر مجاز");
              return;
            }
            this.setState({
              contact: data.contact,
              template_answers: data.template_answers,
            });
            this.setState({
              faq_id: contactId,
              contact: data.faq,
              user_id: data.faq.user_id,
              question: data.faq.question,
            });
            this.generateSearchTemplateAnswers(data.template_answers);
          })
          .catch((error) => {
            console.log(error);
          });
      else
        getContact(contactId)
          .then((data) => {
            if (data.forbidden === true) {
              showError(this.props, "عملیات غیر مجاز");
              return;
            }
            this.setState({
              contact: data.contact,
              template_answers: data.template_answers,
            });
            this.setState({
              contact_id: contactId,
              contact: data.contact,
              user_id: data.contact.user_id,
              question: data.contact.message,
            });
            this.generateSearchTemplateAnswers(data.template_answers);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleTemplateAnswer = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    const answer = this.state.template_answers.filter(
      (ans) => ans.order == parseInt(event.target.value)
    )[0].answer;

    let message = this.state.message;
    //if (message) message = `${message}\nبا سلام\n${answer}`;
    //else message = `با سلام\n${answer}`;
    message = `با عرض سلام و وقت بخیر\n${answer}`;
    this.setState({
      message,
    });
  };

  generateSearchTemplateAnswers = (template_answers) => {
    const search_template_titles = [];
    const search_template_answers = [];
    for (let index = 0; index < template_answers.length; index++) {
      const element = template_answers[index];
      search_template_titles.push({
        value: element._id,
        label: element.title,
        answer: element.answer,
      });
      search_template_answers.push({
        value: element._id,
        label: element.answer,
        answer: element.answer,
      });
    }
    this.setState({ search_template_titles, search_template_answers });
  };

  TYPE_PUBLIC = 1;
  TYPE_PERSONAL = 2;
  TYPE_EDUCATIONAL = 3;
  TYPE_MEMBERSHIP_PERSONAL = 4;

  handleSave = () => {
    const title = this.state.title;
    const message = this.state.message;
    if (!title || !message) {
      this.setState({
        title_error: !title,
        message_error: !message,
      });
      showError(this.props, "فیلدهای لازم را وارد نکرده اید");
      return;
    }

    this.setState({
      loading: true,
      title_error: false,
      message_error: false,
    });

    let user_id = this.state.user_id;
    const type = parseInt(this.state.type);
    if (type == this.TYPE_PUBLIC && user_id) {
      showError(this.props, "پیام عمومی نباید شناسه کاربر داشته باشد");
      return;
    } else if (type == this.TYPE_PERSONAL && !user_id) {
      showError(this.props, "پیام شخصی باید شناسه کاربر داشته باشد");
      return;
    } else if (type == this.TYPE_MEMBERSHIP_PERSONAL && user_id) {
      showError(
        this.props,
        "پیام کاربران اشتراکی نباید شناسه کاربر داشته باشد"
      );
      return;
    }
    if (type == this.TYPE_EDUCATIONAL) user_id = "public";

    const inbox = {
      secret: "909g37312h#",
      title,
      message,
      user_id,
      type,
      question: this.state.question,
    };

    const notif_all = this.state.notif_all;
    const image_url = this.state.image_url;
    const link_name = this.state.link_name;
    const link_url = this.state.link_url;
    const tags = this.state.tags;
    const membership = this.state.membership;
    if (image_url) inbox.image_url = image_url;
    if (link_name) inbox.link_name = link_name;
    if (link_url) inbox.link_url = link_url;
    if (tags) inbox.tags = tags;
    if (type == this.TYPE_PUBLIC) {
      if (membership) inbox.membership = membership;
      if (membership && notif_all) inbox.notif_all = notif_all;
    }

    console.log("inbox", inbox);

    if (type == this.TYPE_PERSONAL) {
      const contact_id = this.state.contact_id;
      const faq_id = this.state.faq_id;
      if (contact_id) inbox.contact_id = contact_id;
      else if (faq_id) inbox.faq_id = faq_id;
    }

    if (this.state.edit) inbox.id = this.state.inbox.id;

    addInbox(inbox)
      .then((data) => {
        this.setState({ loading: false });
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ارسال پیام پیش آمد");
          return;
        }

        showInfo(this.props, "پیام با موفقیت ارسال گردید");
        if (!this.state.edit && data.id)
          this.setState({
            inbox: { ...inbox, id: data.id },
            edit: true,
          });
        this.setState({
          saved: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        showError(this.props, "اشکالی در ارسال پیام پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const { member } = this.state;
    const {
      loading,
      user_id,
      question,
      title,
      message,
      template_answers,
      selected_template_answer,
      search_template_titles,
      search_template_answers,
      showMoreSettings,
      image_url,
      link_url,
      link_name,
      tags,
      type,
      membership,
      notif_all,
      title_error,
      message_error,
      image,
      file,
      uploading,
      sending,
      success,
      fail,
    } = this.state;

    if (this.state.saved === true) {
      return <Redirect to="/inbox" />;
    }

    return (
      <>
        <Grid container direction="column">
          <InputRow
            id="user_id"
            value={user_id}
            onChange={this.handleChange}
            label="کاربر"
            classes={classes}
          />
          <InputRow
            id="question"
            value={question}
            onChange={this.handleChange}
            label="سوال"
            classes={classes}
            multiline={true}
            rows="6"
          />
          <InputRow
            id="title"
            value={title}
            onChange={this.handleChange}
            label="عنوان"
            classes={classes}
            error={title_error}
          />
          <InputRow
            id="message"
            value={message}
            onChange={this.handleChange}
            label="پیام"
            classes={classes}
            error={message_error}
            multiline={true}
            rows="6"
          />
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
            spacing={2}
            style={{ marginTop: 8 }}
          >
            <Grid item xs={1}>
              <Link
                to={{
                  pathname: `/templateAnswers`,
                }}
              >
                <img
                  src={require(`../images/icon_edit.png`)}
                  style={{
                    maxWidth: "40px",
                  }}
                  //className={classes.editIcon}
                  title="جواب های آماده"
                />
              </Link>
            </Grid>
            <Grid item xs={9}>
              <Select
                value={selected_template_answer}
                onChange={this.handleTemplateAnswer}
                inputProps={{
                  name: "selected_template_answer",
                  id: "selected_template_answer",
                }}
                input={<OutlinedInput fullWidth margin="dense" />}
              >
                {template_answers.map((template_answer, index) => (
                  <MenuItem
                    key={index}
                    value={template_answer.order}
                    style={{
                      minHeight: 24,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    <ListItemText
                      primary={template_answer.title}
                      style={{
                        textAlign: "right",
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                      primaryTypographyProps={{ style: { fontSize: 12 } }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography align="left" className={classes.label}>
                :جواب آماده
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            xs={12}
            spacing={2}
            style={{ marginTop: 8 }}
          >
            <Grid item xs={1}></Grid>
            <Grid item xs={9}>
              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                xs={12}
                spacing={1}
              >
                <Grid item xs={12} sm={6}>
                  <ReactSelect
                    options={search_template_answers}
                    placeholder="جستجوی جواب"
                    isClearable={true}
                    isRtl={true}
                    isSearchable={true}
                    onChange={(selectedOption) => {
                      if (!selectedOption) return;
                      let message = this.state.message;
                      message = `با عرض سلام و وقت بخیر\n${selectedOption.answer}`;
                      this.setState({
                        message,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReactSelect
                    options={search_template_titles}
                    placeholder="جستجوی عنوان"
                    isClearable={true}
                    isRtl={true}
                    isSearchable={true}
                    onChange={(selectedOption) => {
                      if (!selectedOption) return;
                      let message = this.state.message;
                      message = `با عرض سلام و وقت بخیر\n${selectedOption.answer}`;
                      this.setState({
                        message,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          {!showMoreSettings && (
            <Grid container direction="row" style={{ marginTop: 16 }}>
              <Grid container item xs={10} justify="flex-end">
                <Grid item>
                  <Button
                    onClick={() => this.setState({ showMoreSettings: true })}
                    color="secondary"
                    style={{ textAlign: "right" }}
                  >
                    نمایش تنظیمات بیشتر
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {showMoreSettings && (
            <>
              <br />
              <InputRow
                id="image_url"
                value={image_url}
                onChange={this.handleChange}
                label="آدرس تصویر"
                classes={classes}
              />
              <Grid container direction="row">
                <Grid container item xs={5}>
                  <InputRow
                    id="link_url"
                    value={link_url}
                    onChange={this.handleChange}
                    label="آدرس لینک"
                    classes={classes}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <InputRow
                    id="link_name"
                    value={link_name}
                    onChange={this.handleChange}
                    label="نام لینک"
                    classes={classes}
                  />
                </Grid>
              </Grid>
              <InputRow
                id="tags"
                value={tags}
                onChange={this.handleChange}
                label="تگ ها"
                classes={classes}
              />
              <Grid container direction="row">
                <Grid container item xs={10} justify="flex-end">
                  <Grid item>
                    <Typography
                      align="right"
                      variant="caption"
                      className={classes.label}
                    >
                      تگ ها با ، از یکدیگر جدا شوند
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                xs={12}
                spacing={2}
                style={{ marginTop: 8 }}
              >
                <Grid item xs={10}>
                  <RadioGroup
                    row={true}
                    id="type"
                    aria-label="type"
                    name="type"
                    className={classes.radioGroup}
                    value={type}
                    onChange={this.handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="secondary" />}
                      label="عمومی"
                      labelPlacement="start"
                      className={classes.label}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio color="secondary" />}
                      label="پیام شخصی برای همه کاربران اشتراکی"
                      labelPlacement="start"
                      className={classes.label}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="secondary" />}
                      label="شخصی"
                      labelPlacement="start"
                      className={classes.label}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={2}>
                  <Typography align="left" className={classes.label}>
                    :نوع پیام
                  </Typography>
                </Grid>
              </Grid>
              {type === this.TYPE_PUBLIC.toString() && (
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                  spacing={3}
                >
                  <Grid item xs={10} style={{ textAlign: "right" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleCheck}
                          name="membership"
                          checked={membership}
                        />
                      }
                      label="قابل مشاهده فقط برای کاربران دارای اشتراک"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      align="left"
                      className={classes.label}
                    ></Typography>
                  </Grid>
                </Grid>
              )}
              {type === this.TYPE_PUBLIC.toString() && this.state.membership && (
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                  spacing={3}
                >
                  <Grid item xs={10} style={{ textAlign: "right" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={this.handleCheck}
                          name="notif_all"
                          checked={notif_all}
                        />
                      }
                      label="ارسال نوتیفیکیشن برای همه"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      align="left"
                      className={classes.label}
                    ></Typography>
                  </Grid>
                </Grid>
              )}
              {false && (
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                  style={{ marginTop: "8px" }}
                >
                  <Grid item>
                    <Dropzone
                      hasId={true}
                      getFaces={false}
                      onDrop={this.onDrop}
                    />
                  </Grid>
                  {file && (
                    <Grid item>
                      <MyButton
                        className={classes.upload}
                        variant="contained"
                        onClick={this.handleUpload}
                        disabled={uploading}
                      >
                        بارگذاری تصویر جدید
                      </MyButton>
                    </Grid>
                  )}
                  {file && (
                    <Grid item>
                      <img
                        src={file.preview}
                        className={classes.imagePreview}
                        alt=""
                      />
                    </Grid>
                  )}
                  {this.state.member && !this.state.newImage && (
                    <Grid item>
                      <img
                        src={getInboxImagePath(`${this.state.member.id}.jpg`)}
                        className={classes.memberEditImagePlate}
                        alt=""
                      />
                    </Grid>
                  )}
                  {this.state.newImage && (
                    <Grid item>
                      <img
                        src={this.state.newImage}
                        className={classes.memberEditImagePlate}
                        alt=""
                      />
                    </Grid>
                  )}
                  <Grid item xs={2}></Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={4}
          style={{ marginTop: 16 }}
        >
          <Grid item>
            <Link to="/inbox" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                بازگشت
              </MyButton>
            </Link>
          </Grid>

          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
              disabled={loading}
            >
              ارسال
            </MyButton>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewInbox)
);
