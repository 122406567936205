import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

export default function ConfirmDialog(props) {
  /*
const {
      title,
      content,
      visible,
      handleConfirm,
      handleCancel,
      confirmText,
      cancelText,
      hideCancel,
      dismissable,
      confirmButtonContained,
      cancelButtonContained
    } = this.props;
  */

  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.hideCancel}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ direction: "rtl" }}
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h6" color="primary">
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleConfirm} color="secondary" autoFocus>
          بله
        </Button>
        <Button onClick={props.handleCancel}>خیر</Button>
      </DialogActions>
    </Dialog>
  );
}
