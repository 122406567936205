import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import BoxTitle from "../components/boxTitle";
import AdminsContainer from "../containers/admin/admins";

class Admins extends Component {
  render() {
    const { classes } = this.props;
    return <AdminsContainer />;
  }
}

export default withStyles(styles)(Admins);
