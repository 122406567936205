/*const styles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px"
  }
});*/

const styles = {
  root: {
    //height: "100%",
    //background: "#000000",
    margin: "0 auto",
    //height: "100vh",
    height: "100%"
  },
  content: {
    //maxWidth: 800,
    margin: "0 auto",
    //padding: `5px 20px`,
    height: "100%"
    //maxHeight: "80vh"
    //height: "calc(100% - 120px)"
    //height: "90%"
    //height: "50vh"
  },
  relative: { position: "relative" },
  boxRelative: {
    position: "relative",
    height: "100%"
    //height: "calc(100% - 150px)"
    //height: "75vh"
  },
  boxHeader: {
    backgroundImage: `url(${require(`../images/box_header_1.png`)})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    //backgroundSize: "contain",
    backgroundSize: "100% 48x",
    backgroundOrigin: "border-box",
    height: "48px",
    color: "white"
    //padding: "0 30px"
  },
  boxBorder: {
    //borderImage: `url(${require(`../images/box_border_1.png`)}) 93 92 87 92 stretch stretch`,
    borderImage: `url(${require(`../images/box_border_1.png`)}) 65 stretch`,
    //borderImageSlice: 20,
    //borderImageWidth: 528,
    //borderImageOutset: "10px",
    //borderImageRepeat: "round",
    borderTop: "60px solid transparent",
    borderBottom: "40px solid transparent",
    borderRight: "40px solid transparent",
    borderLeft: "40px solid transparent",
    //height: "100%",
    //position: "relative",
    //padding: 0,
    //paddingTop: "20px",
    height: "calc(100% - 100px)",
    maxHeight: "calc(100% - 100px)"

    //height: "100%"
  },
  boxBorderScroll: {
    borderImage: `url(${require(`../images/box_border_1.png`)}) 65 stretch`,
    borderTop: "60px solid transparent",
    borderBottom: "40px solid transparent",
    borderRight: "40px solid transparent",
    borderLeft: "40px solid transparent",
    minHeight: "calc(100% - 100px)"
  },
  boxFlex: {
    display: "flex"
  },
  boxTitle: {
    position: "absolute",
    top: 0,
    //right: 20,
    //left: 20,
    //margin: "0px 10px 10px 0px",
    color: "white",
    //lineHeight: "60px",
    width: "100%",
    height: "70px"
  },
  boxTitleContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    marginLeft: "-15px"
  },
  boxTitleIcon: {
    height: "16px",
    marginLeft: 8
  },
  boxTitleMore: { fontSize: "11px" },
  boxTitleLabel: { fontSize: "11px", color: "#4b96d7" },
  boxTitleCount: { fontSize: "11px", color: "#c1b225" },
  button: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px"
  },
  live: {
    //marginTop: "32px"
  },
  liveContainer: {
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    marginTop: "8px"
  },
  camera: {
    color: "#4b96d7",
    fontSize: "10px",
    margin: "0px 16px"
  },
  cameraSelected: {
    color: "white"
  },
  liveBorder: {
    border: "2px solid #28618e",
    borderRadius: "2px",
    maxWidth: "100%",
    maxHeight: "40vh"
  },
  captureBorder: {
    borderImage: `url(${require(`../images/capture_border.png`)}) 10 stretch`,
    border: "5px solid transparent",
    width: "100%",
    height: "auto"
  },
  captureBorderGreen: {
    borderImage: `url(${require(`../images/capture_border_green.png`)}) 10 stretch`
  },
  captureBorderRed: {
    borderImage: `url(${require(`../images/capture_border_red.png`)}) 10 stretch`
  },
  captureImage: {
    height: "8vh",
    objectFit: "contain",
    cursor: "pointer"
  },
  captureTime: {
    color: "#4b96d7",
    fontSize: "10px"
  },
  captureName: {
    color: "#B5D9F8",
    fontSize: "10px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  capturePlate: {
    color: "#4b96d7",
    display: "flex",
    justifyContent: "center",
    "& p": {
      fontSize: "12px"
    }
  },
  captureScroll: {
    //height: "25vh",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    paddingRight: "16px",
    marginTop: "16px"
  },
  captureItem: {
    animation: "slide-up 0.2s ease"
  },
  captureGender: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "18px"
    //marginLeft: 8
  },
  captureAge: {
    position: "absolute",
    bottom: 0,
    right: "-11%",
    color: "#000",
    fontSize: "10px",
    backgroundColor: "white",
    borderRadius: "2px",
    padding: "1px",
    paddingRight: "3px",
    paddingLeft: "3px"
    //width: "24px",
    //height: "24px",
    //lineHeight: "12px"
  },
  targetContent: {
    //background: "rgb(2,37,67)",
    background:
      "linear-gradient(0deg, rgba(2,37,67,0.85) 0%, rgba(0,51,96,0.85) 100%)",
    borderRadius: "4px",
    padding: "2px 16px 8px 16px",
    marginTop: "12px"
  },
  targetContentGreen: {
    //background: "rgb(2,37,67)",
    background:
      "linear-gradient(0deg, rgba(2,90,67,0.85) 0%, rgba(0,51,96,0.85) 100%)"
  },
  targetContentRed: {
    //background: "rgb(2,37,67)",
    background:
      "linear-gradient(0deg, rgba(100,37,67,0.85) 0%, rgba(0,51,96,0.85) 100%)"
  },
  targetBorder: {
    border: "2px solid red",
    borderRadius: "2px",
    //maxWidth: "80%",
    maxWidth: "8vh",
    height: "8vh"
    //objectFit: "contain"
    //width: "100%",
    //height: "auto"
  },
  targetScroll: {
    //height: "72vh",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    //padding: "16px",
    paddingRight: "16px"
    //marginTop: "8px"
  },
  targetCaptureBorder: {
    border: "2px solid #28618e",
    borderRadius: "2px",
    //width: "80%"
    //maxWidth: "80%",
    maxWidth: "8vh",
    height: "8vh",
    objectFit: "contain"
  },
  targetCapturePlateBorder: {
    border: "2px solid #28618e",
    borderRadius: "2px",
    maxWidth: "100%",
    objectFit: "contain"
  },
  targetSimilarityContainer: {
    position: "absolute",
    bottom: 0,
    left: "0%",
    width: "100%",
    height: "18px"
  },
  targetSimilarity: {
    color: "white",
    fontSize: "11px",
    lineHeight: "20px"
  },
  targetComparisonTitle: {
    color: "#4b96d7",
    fontSize: "10px"
  },
  targetComparisonIcon: {
    height: "12px",
    marginLeft: "4px"
  },
  targetSimilarityIcon: {
    height: "40px"
  },
  targetName: {
    color: "white",
    fontSize: "10px"
  },
  targetPlateImage: {
    height: "32px"
  },
  targetPlateCenter: {
    position: "absolute",
    //left: "10%",
    //right: "25%",
    top: "6%", //"10%",
    color: "#000",

    display: "flex",
    left: "14%",
    right: "25%",
    justifyContent: "center"
    //fontSize: "3vw"
  },
  targetPlateRight: {
    position: "absolute",
    left: "75%",
    right: "5%",
    top: "25%",
    color: "#000"
    //fontSize: "2vw"
  },
  targetSimilarityHigh: {
    background: "rgba(100, 0, 0, 0.8)"
  },
  targetSimilarityMedium: {
    background: "rgba(220, 178, 9, 0.8)"
  },
  targetSimilarityLow: {
    background: "rgba(143, 183, 6, 0.8)"
  },
  targetBorderHigh: {
    border: "2px solid red",
    borderRadius: "2px"
  },
  targetBorderMedium: {
    border: "2px solid orange",
    borderRadius: "2px"
  },
  targetBorderLow: {
    border: "2px solid yellowgreen",
    borderRadius: "2px"
  },
  targetAlertContent: {
    position: "fixed",
    //width: "100vw",
    //height: "25vh",
    left: 0,
    right: 0,
    top: "50%",
    transform: "translate(0%, -45%)",
    zIndex: 1000,
    //background: "rgba(172,34,34,0.9)",
    padding: "8px 16px 8px 16px",
    overflow: "hidden"
  },
  targetAlertContentRed: {
    background: "rgba(172,34,34,0.9)"
  },
  targetAlertContentGreen: {
    background: "rgba(30,132,30,0.9)"
  },
  alertTime: {
    color: "white",
    fontSize: "16px"
  },
  alertComparisonTitle: {
    color: "white",
    fontSize: "16px",
    opacity: 0.6
  },
  alertComparisonIcon: {
    height: "24px",
    marginLeft: "8px",
    opacity: 0.6
  },
  alertTargetName: {
    color: "white",
    fontSize: "22px",
    marginTop: "8px",
    marginRight: "8px"
  },
  alertSimilarityTitle: {
    color: "white",
    marginBottom: "16px"
  },
  alertSimilarity: {
    color: "white"
    //fontSize: "32px",
  },
  alertImage: {
    //width: "15vw",
    maxWidth: "95%",
    height: "20vh",
    objectFit: "contain"
  },
  alertCaptureImage: {
    marginRight: "12px",
    border: "2px solid #28618e",
    borderRadius: "2px"
    //borderImage: `url(${require(`../images/capture_border.png`)}) 10 stretch`,
    //border: "5px solid transparent",
    //height: "auto"
  },
  alertTargetImage: {
    marginLeft: "12px"
  },
  alertLine: {
    width: "90vw"
    //border: "0.1px solid #BDBDBD"
  },
  hide: {
    display: "none"
  },
  minHeight0: {
    minHeight: 0
  },
  maxHeight100: {
    maxHeight: "100%"
  },
  plateLetter: {
    width: "100%",
    fontSize: "12px",
    padding: 0,
    color: "black",
    "& .ant-select-selection__rendered": {
      marginRight: 0,
      marginLeft: "30%"
    }
  },
  plateClear: {
    position: "absolute",
    top: -8,
    left: -8,
    cursor: "pointer",
    height: "16px"
    //marginLeft: 8
  },
  memberInfoDialog: {}
};

export default styles;
