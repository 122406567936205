import { CHECKOUT_ENDPOINT, BASE_URL_OTHERS } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getCheckout = (checkoutId) => {
  return myFetch(`${CHECKOUT_ENDPOINT}/${checkoutId}`, "GET");
};

export const getCheckouts = (userId) => {
  return myFetch(`${CHECKOUT_ENDPOINT}/${userId}`, "GET");
};

export const setCheckout = (checkoutId, paymentId) => {
  return myFetch(`${CHECKOUT_ENDPOINT}`, "POST", {
    checkoutId,
    paymentId,
  });
};

export const addCheckout = (warrant) => {
  return warrant._id
    ? myFetch(`${CHECKOUT_ENDPOINT}`, "PUT", {
        warrant: warrant,
      })
    : myFetch(`${CHECKOUT_ENDPOINT}`, "POST", {
        warrant: warrant,
      });
};

export const removeCheckout = (checkoutId) => {
  return myFetch(`${CHECKOUT_ENDPOINT}`, "DELETE", {
    checkoutId: checkoutId,
  });
};
