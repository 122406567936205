import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import { Typography, Card, Paper } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { getContacts } from "../../services/contacts";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { Link } from "react-router-dom";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { getFAQs } from "../../services/faq";
import { withSnackbar } from "notistack";
import stylesContacts from "../../styles/contacts";
import { initACL } from "../../helpers/acl";
import { addRate } from "../../services/rate";
import { Pagination } from "antd";

class Contacts extends Component {
  state = {
    contacts: [],
    isFAQ: false,
    pathname: "",
    currentPage: 1,
  };

  componentDidMount = async () => {
    this.loadContacts();
  };

  componentDidUpdate(prevProps, prevState) {
    this.loadContacts();

    const result = initACL(this.state.initACL, 0);
    if (result.length > 0) {
      this.setState({
        enableUserInfoButton: result[3],
        initACL: true,
      });
    }
  }

  loadContacts(page, pageSize) {
    if (this.state.pathname === window.location.pathname && !page) return;
    if (!page) this.setState({ currentPage: 1 });
    this.setState({ contacts: [] });
    let user_id = this.state.user_id;
    if (!user_id) user_id = "-1";
    if (window.location.pathname.includes("faq")) {
      this.setState({ isFAQ: true, pathname: window.location.pathname });
      getFAQs(user_id, page, pageSize)
        .then((data) => {
          if (data.forbidden === true) {
            this.setState({ contacts: [] });
            showError(this.props, "عملیات غیر مجاز");
            return;
          }
          this.setState({ contacts: data.faqs });
        })
        .catch((error) => {});
    } else {
      this.setState({ isFAQ: false, pathname: window.location.pathname });
      getContacts(user_id, page, pageSize)
        .then((data) => {
          if (data.forbidden === true) {
            this.setState({ contacts: [] });
            showError(this.props, "عملیات غیر مجاز");
            return;
          }
          this.setState({ contacts: data.contacts });
        })
        .catch((error) => {});
    }
  }

  addRate(userId) {
    console.log(userId);
    if (!userId) return;

    const rate = {
      secret: "0g9gu7I16h#",
      user_id: userId,
    };

    addRate(rate)
      .then((data) => {
        this.setState({ loading: false });
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در اضافه کردن رای به برنامه پیش آمد");
          return;
        }

        showInfo(this.props, "رای به برنامه با موفقیت اضافه گردید");
      })
      .catch((error) => {
        this.setState({ loading: false });
        showError(this.props, "اشکالی در اضافه کردن رای به برنامه پیش آمد");
      });
  }

  handlePageChange = (page, pageSize) => {};

  render() {
    const { classes } = this.props;
    const { contacts, isFAQ, user_id, enableUserInfoButton } = this.state;

    return (
      <Grid container direction="row" /*className={classes.liveContainer}*/>
        {user_id && (
          <img
            src={require(`../../images/icon_delete_image.png`)}
            style={{ width: 32, cursor: "pointer" }}
            onClick={() => {
              this.setState({ user_id: "", pathname: "" }, () =>
                this.loadContacts()
              );
            }}
            title="حذف فیلتر"
          />
        )}
        <Grid
          container
          direction="row"
          spacing={2}
          //style={{ maxWidth: "1000px" }}
        >
          {contacts &&
            contacts.map((contact, index) => (
              <Grid key={index} item xs={12}>
                <Card>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    //className={classes.memberContent}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      xs={11}
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid
                        item
                        container
                        direction="column"
                        xs={2}
                        //xs={5}
                        sm={1}
                        //md={3}
                        //lg={2}
                        spacing={1}
                      >
                        <Grid item>
                          <Link
                            to={{
                              pathname: `/inbox/${contact._id}`,
                              isFAQ,
                            }}
                          >
                            <img
                              src={require(`../../images/icon_edit.png`)}
                              style={{
                                maxWidth: "100%",
                              }}
                              //className={classes.editIcon}
                              title="ارسال پاسخ"
                            />
                          </Link>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        alignItems="flex-end"
                        xs={10}
                        //xs={7}
                        sm={11}
                        //md={9}
                        //lg={10}
                        spacing={0}
                        style={{ margin: 4 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            className={classes.memberName}
                            variant="subtitle2"
                          >
                            {dateTimeToPersian(contact.created_date)}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          spacing={2}
                        >
                          {enableUserInfoButton && (
                            <Grid item>
                              <Link
                                to={{
                                  pathname: `/user/search`,
                                  userId: contact.user_id,
                                }}
                              >
                                <img
                                  src={require(`../../images/icon_user.png`)}
                                  style={{
                                    width: "18px",
                                  }}
                                  //className={classes.editIcon}
                                  title="اطلاعات کاربر"
                                />
                              </Link>
                            </Grid>
                          )}
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="subtitle2"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "darkblue",
                              }}
                              onClick={() => {
                                this.setState(
                                  { user_id: contact.user_id, pathname: "" },
                                  () => this.loadContacts()
                                );
                              }}
                            >
                              {contact.user_id}
                            </Typography>
                          </Grid>
                        </Grid>

                        {!isFAQ && (
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="body2"
                              >
                                {contact.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="body2"
                              >
                                :نام
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {!isFAQ && (
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="body2"
                              >
                                {contact.phone}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="body2"
                              >
                                :شماره
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {!isFAQ && (
                          <Grid item xs={12}>
                            <Typography
                              className={classes.memberName}
                              variant="body2"
                              style={{ textAlign: "right", direction: "rtl" }}
                            >
                              {contact.message}
                            </Typography>
                          </Grid>
                        )}
                        {isFAQ && (
                          <Grid item xs={12}>
                            <Typography
                              className={classes.memberName}
                              variant="body2"
                              style={{ textAlign: "right", direction: "rtl" }}
                            >
                              {contact.question}
                            </Typography>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="body2"
                            >
                              {contact.purchase &&
                                `(آخرین خرید: ${contact.purchase.name})`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="body2"
                              style={{
                                color:
                                  new Date(contact.membership) > new Date()
                                    ? "limegreen"
                                    : "red",
                              }}
                            >
                              {new Date(contact.membership) > new Date()
                                ? "اشتراک دارد"
                                : "اشتراک ندارد"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {contact.purchase && (
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            spacing={1}
                            style={{ color: "gray" }}
                          >
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="caption"
                              >
                                {dateTimeToPersian(
                                  contact.purchase.created_date
                                )}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="caption"
                              >
                                :تاریخ آخرین خرید
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {contact.membership && (
                          <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            spacing={1}
                            style={{ color: "gray" }}
                          >
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="caption"
                              >
                                {dateTimeToPersian(contact.membership)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.memberName}
                                variant="caption"
                              >
                                :تاریخ اتمام اشتراک
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="body2"
                              style={{ fontFamily: "Roboto, sans-serif" }}
                            >
                              {contact.device}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="body2"
                            >
                              :دستگاه و نسخه
                            </Typography>
                          </Grid>
                        </Grid>
                        {contact.reply_inbox_id && (
                          <Grid item xs={12}>
                            <Typography
                              className={classes.replied}
                              variant="body2"
                            >
                              پاسخ داده شده
                            </Typography>
                          </Grid>
                        )}
                        {contact.reply_inbox_id && (
                          <Grid
                            container
                            direction="column"
                            justify="flex-end"
                            className={classes.replyGrid}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="body2"
                                >
                                  ({contact.supporter_username})
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="body2"
                                >
                                  {contact.supporter_name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="body2"
                                >
                                  :پشتیبان
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                className={classes.memberName}
                                variant="body2"
                                style={{
                                  textAlign: "right",
                                  direction: "rtl",
                                  marginRight: "8px",
                                  marginLeft: "8px",
                                }}
                              >
                                {contact.inbox.message}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="body2"
                                >
                                  {contact.inbox.voters_down
                                    ? contact.inbox.voters_down.length
                                    : 0}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <img
                                  style={{
                                    width: "16px",
                                  }}
                                  src={require(`../../images/thumb_down.png`)}
                                />
                              </Grid>

                              <Grid item>
                                <img
                                  style={{
                                    width: "16px",
                                    marginLeft: "16px",
                                  }}
                                  src={require(`../../images/thumb_up.png`)}
                                />
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="body2"
                                >
                                  {contact.inbox.voters_up
                                    ? contact.inbox.voters_up.length
                                    : 0}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <img
                              src={require(`../../images/icon_rating.png`)}
                              style={{
                                maxWidth: "24px",
                                cursor: "pointer",
                              }}
                              //className={classes.editIcon}
                              onDoubleClick={() =>
                                this.addRate(contact.user_id)
                              }
                              title="رای به برنامه"
                            />
                          </Grid>
                          {contact.rate && (
                            <>
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="caption"
                                >
                                  (
                                  {dateTimeToPersian(contact.rate.created_date)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.memberName}
                                  variant="caption"
                                >
                                  :ارسال شده در)
                                </Typography>
                              </Grid>
                            </>
                          )}
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="caption"
                            >
                              {contact.rate && contact.rate.rated_date
                                ? dateTimeToPersian(contact.rate.rated_date)
                                : "---"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.memberName}
                              variant="caption"
                            >
                              :آخرین رای به برنامه
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <img
                        src={require(`../../images/${
                          isFAQ ? "faq" : "contact"
                        }.png`)}
                        style={{
                          maxWidth: "100%",
                        }}
                        className={classes.memberImage}
                        alt=""
                        onError={() => this.noDefaultImage(index)}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
        </Grid>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ marginTop: "48px", marginBottom: "32px" }}
        >
          <Pagination
            current={this.state.currentPage}
            hideOnSinglePage
            defaultCurrent={1}
            total={500}
            pageSize={20}
            onChange={(page, pageSize) => {
              console.log("Pagination page:", page);
              console.log("Pagination pageSize:", pageSize);
              this.setState({ currentPage: page });
              this.loadContacts(page, pageSize);
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles({ ...styles, ...stylesContacts })(
  withSnackbar(Contacts)
);
