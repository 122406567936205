import { createSlice } from "redux-starter-kit";
import { getUser } from "../helpers/user";

const userSlice = createSlice({
  slice: "user",
  initialState: getUser(),
  reducers: {
    saveUser(state, action) {
      const user = action.payload;
      return user;
    },
    removeUser(state, action) {
      return null;
    },
    saveUserACL(state, action) {
      const user = state;
      user.acl = action.payload;
      return user;
    }
  }
});

export const { saveUser, removeUser, saveUserACL } = userSlice.actions;

export default userSlice.reducer;
