import { PERMISSION_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getPermission = permissionId => {
  return myFetch(`${PERMISSION_ENDPOINT}/${permissionId}`, "GET");
};

export const getPermissions = () => {
  return myFetch(`${PERMISSION_ENDPOINT}`, "GET");
};

export const getPermissionsList = () => {
  return myFetch(`${PERMISSION_ENDPOINT}/list`, "GET");
};

export const addPermission = permission => {
  return permission.id
    ? myFetch(`${PERMISSION_ENDPOINT}`, "PUT", {
        permission: permission
      })
    : myFetch(`${PERMISSION_ENDPOINT}`, "POST", {
        permission: permission
      });
};

export const removePermission = permissionId => {
  /*return Promise.resolve({
    ok: true,
    status: 400,
    json: () => [{ ok: true }]
  });*/

  return myFetch(`${PERMISSION_ENDPOINT}`, "DELETE", {
    permissionId: permissionId
  });
};
