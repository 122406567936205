import styled from "styled-components";
import Button from "@material-ui/core/Button";

/*const StyledButton = styled(Button)`
  .MuiButton-contained {
    color: green;
    background-color: green;
  }
  .MuiButton-root {
    display: none;
    color: green;
    background-color: green;
  }
  label.focused {
    color: green;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-color: yellow;
    }
    &.Mui-focused fieldset {
      border-color: green;
    }
  }
`;*/

const StyledButton = styled(Button)`
  background: linear-gradient(
    0deg,
    rgba(67, 2, 2, 0.85) 0%,
    rgba(96, 0, 0, 0.85) 100%
  );

  .MuiButton-contained {
    border-radius: 20px;
    border: 10px solid rgba(96, 0, 0, 0.85);
  }

  & .MuiButton-label {
    color: white;
  }
`;

export default StyledButton;
