import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import { Typography } from "@material-ui/core";

function BoxTitle(props) {
  const { classes, hideMore, infoCount, infoLabel, title, icon } = props;
  return (
    <div className={classes.boxTitle}>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.boxTitleContent}
      >
        <Grid item xs={!hideMore ? 3 : 1}>
          {!hideMore && (
            <Typography variant="caption" className={classes.boxTitleMore}>
              🢔 بیشتر
            </Typography>
          )}
        </Grid>
        <Grid item container direction="row" xs={!hideMore ? 3 : 5} spacing={1}>
          <Grid item>
            <Typography variant="caption" className={classes.boxTitleCount}>
              {infoCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" className={classes.boxTitleLabel}>
              {infoLabel}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} alignItems="center" justify="flex-end">
          <Grid item>
            <Typography variant="body2" align="right">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={require(`../images/${icon}.png`)}
              className={classes.boxTitleIcon}
              alt=""
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(BoxTitle);
