import jMoment from "moment-jalaali";
jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

export const dateToPersian = date => {
  return jMoment(date).format("jYYYY/jMM/jDD");
};

export const dateTimeToPersian = date => {
  try {
    return jMoment(date).format("jYYYY/jM/jD , HH:mm:ss");
  } catch (ex) {}
};
