import { ASSEMBLY_ENDPOINT, BASE_URL_OTHERS } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getAssembly = (assemblyId) => {
  return myFetch(`${ASSEMBLY_ENDPOINT}/${assemblyId}`, "GET");
};

export const getAssemblies = () => {
  return myFetch(`${ASSEMBLY_ENDPOINT}`, "GET");
};

export const addAssembly = (assembly) => {
  return assembly._id
    ? myFetch(`${ASSEMBLY_ENDPOINT}`, "PUT", {
        assembly: assembly,
      })
    : myFetch(`${ASSEMBLY_ENDPOINT}`, "POST", {
        assembly: assembly,
      });
};

export const removeAssembly = (assemblyId) => {
  return myFetch(`${ASSEMBLY_ENDPOINT}`, "DELETE", {
    assemblyId: assemblyId,
  });
};

export const clearAssembliesCache = () => {
  /*BASE_URL_OTHERS.forEach((BASE_URL) => {
    myFetch(`${BASE_URL}/assemblies/cache/clear`, "GET");
  });*/
  return myFetch(`${ASSEMBLY_ENDPOINT}/cache/clear`, "GET");
};
