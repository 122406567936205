import React from "react";
import "./App.css";
import Master from "./pages/master";
import Stats from "./pages/stats";
import StatsPWA from "./pages/statsPWA";
import Inbox from "./pages/inbox";
import NewInbox from "./pages/newInbox";
import Contacts from "./pages/contacts";
import Login from "./pages/login";
import Admins from "./pages/admins";
import NewAdmin from "./pages/newAdmin";
import SearchUser from "./pages/searchUser";
import Report from "./pages/report";
import NewNotif from "./pages/newNotif";
import TemplateAnswers from "./pages/templateAnswers";
import NewTemplateAnswer from "./pages/newTemplateAnswer";
import NewWarrant from "./pages/newWarrant";
import Data from "./pages/data";
import Filter from "./pages/filter";
import NewAssembly from "./pages/newAssembly";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import VazirFDWoff2 from "./fonts/Vazir-FD.woff2";
import VazirFDWoff from "./fonts/Vazir-FD.woff";
import VazirFDTtf from "./fonts/Vazir-FD.ttf";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
//import "antd/dist/antd.css";
import "./ant.css";
import { connect } from "react-redux";

const vazir = {
  fontFamily: "Vazir",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(${VazirFDWoff2}) format('woff2')
    url(${VazirFDWoff}) format("woff"),
    url(${VazirFDTtf}) format("truetype");
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
  primary: {
    // light: will be calculated from palette.primary.main,
    main: "#8F0000",
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
    contrastText: "#FF0000",
  },
  secondary: {
    main: "#333333",
    // dark: will be calculated from palette.secondary.main,
    contrastText: "#FF0000",
  },
  text: {
    secondary: "#6E1717",
  },
  /*palette: {
    background: { paper: "#8F0E0E" }
  },*/
  /*palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#fff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: "#ffcc00"
    },
    secondary: {
      main: "#333333",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00"
    },
    text: {
      secondary: "#28618e"
    }
    // error: will use the default color
    //background: { paper: "#E0F7FF" }
  },*/
  typography: {
    fontFamily: ["Vazir"].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [vazir],
      },
    },
    MuiButton: {
      root: {
        borderRadius: 20,
        paddingRight: 40,
        paddingLeft: 40,
      },
    },
  },
});

function App(props) {
  const { user } = props;

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              {!user ? (
                <Redirect to="/login" />
              ) : (
                <Master>
                  <Switch>
                    <Route exact path="/" component={Stats} />
                    <Route exact path="/pwa" component={StatsPWA} />
                    <Route exact path="/inbox" component={Inbox} />
                    <Route exact path="/inbox/:id" component={NewInbox} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route exact path="/faq" component={Contacts} />
                    <Route exact path="/admins" component={Admins} />
                    <Route exact path="/admins/:id" component={NewAdmin} />
                    <Route exact path="/admins/new" component={NewAdmin} />
                    <Route exact path="/user/search" component={SearchUser} />
                    <Route exact path="/report" component={Report} />
                    <Route exact path="/notif" component={NewNotif} />
                    <Route
                      exact
                      path="/templateAnswers"
                      component={TemplateAnswers}
                    />
                    <Route
                      exact
                      path="/templateAnswers/:id"
                      component={NewTemplateAnswer}
                    />
                    <Route
                      exact
                      path="/templateAnswers/new"
                      component={NewTemplateAnswer}
                    />
                    <Route exact path="/warrants/:id" component={NewWarrant} />
                    <Route exact path="/warrants/new" component={NewWarrant} />
                    <Route exact path="/data" component={Data} />
                    <Route exact path="/filter" component={Filter} />
                    <Route
                      exact
                      path="/assemblies/:id"
                      component={NewAssembly}
                    />
                    <Route
                      exact
                      path="/assemblies/new"
                      component={NewAssembly}
                    />
                  </Switch>
                </Master>
              )}
            </Switch>
          </BrowserRouter>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(App);
