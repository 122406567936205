import { FAQ_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

//export const getFAQs = userId => {
//  return myFetch(`${FAQ_ENDPOINT}/${userId}`, "GET");
//};

export const getFAQs = (userId, page, pageSize) => {
  return myFetch(`${FAQ_ENDPOINT}`, "GET", {
    userId,
    page,
    pageSize
  });
};

export const getFAQ = faqId => {
  return myFetch(`${FAQ_ENDPOINT}/id/${faqId}`, "GET");
};
