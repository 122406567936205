import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import stylesMembers from "../styles/members";
import MyButton from "../components/button";
import { InputRow } from "../components/inputRow";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../helpers/notification";
import { Link, Redirect } from "react-router-dom";
import {
  getTemplateAnswer,
  addTemplateAnswer,
} from "../services/templateAnswers";

class NewTemplateAnswer extends Component {
  state = {};

  componentDidMount = () => {
    if (this.props.match.params.id) {
      var templateAnswerId = this.props.match.params.id;
      if (!templateAnswerId) return;
      getTemplateAnswer(templateAnswerId)
        .then((data) => {
          if (data.forbidden === true) {
            showError(this.props, "عملیات غیر مجاز");
            return;
          }
          this.setState({
            edit: true,
            templateAnswer_id: templateAnswerId,
            templateAnswer: data,
            admin_id: data.admin_id,
            order: data.order,
            title: data.title,
            answer: data.answer,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /*componentDidUpdate = () => {
    if (this.props.templateAnswer && !this.state.templateAnswer) {
      const templateAnswer = this.props.templateAnswer;
      this.setState({
        edit: true,
        templateAnswer: templateAnswer,
        order: templateAnswer.order,
        title: templateAnswer.title,
        answer: templateAnswer.answer,
      });
    }
  };*/

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = () => {
    const edit = this.state.edit;
    const order = this.state.order;
    const title = this.state.title;
    const answer = this.state.answer;

    if (!title || !answer) {
      this.setState({
        title_error: !title,
        answer_error: !answer,
      });
      return;
    }

    this.setState({
      loading: true,
      title_error: false,
      answer_error: false,
    });

    const templateAnswer = {
      //order,
      title,
      answer,
    };

    if (this.state.edit) {
      templateAnswer._id = this.state.templateAnswer._id;
      templateAnswer.order = this.state.templateAnswer.order;
    }

    addTemplateAnswer(templateAnswer)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ذخیره پیش آمد");
          return;
        }

        showInfo(this.props, "جواب آماده با موفقیت ذخیره گردید");
        if (!this.state.edit && data.id)
          this.setState({
            templateAnswer: { ...templateAnswer, id: data.id },
            edit: true,
          });
        this.setState({
          loading: false,
          saved: true,
        });
      })
      .catch((error) => {
        showError(this.props, "اشکالی در ذخیره پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const {
      //order,
      title,
      answer,
      edit,
      title_error,
      answer_error,
      sending,
      success,
      fail,
    } = this.state;

    if (this.state.saved === true) {
      return <Redirect to="/templateAnswers" />;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div className={classes.memberScroll}>
        <Grid container direction="column">
          <InputRow
            id="title"
            value={title}
            onChange={this.handleChange}
            label="عنوان"
            classes={classes}
            error={title_error}
          />
          <InputRow
            id="answer"
            value={answer}
            onChange={this.handleChange}
            label="پاسخ"
            classes={classes}
            error={answer_error}
            multiline={true}
            rows="6"
          />
        </Grid>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Link to="/templateAnswers" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                بازگشت
              </MyButton>
            </Link>
          </Grid>
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
            >
              ذخیره
            </MyButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewTemplateAnswer)
);
