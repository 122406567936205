import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import MyButton from "../../components/button";
import { InputRow } from "../../components/inputRow";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import { Link, Redirect } from "react-router-dom";
import { addAdmin, changePassword, getACL } from "../../services/admins";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import { getCamerasList } from "../../services/cameras";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";

class NewAdmin extends Component {
  state = { acl: [], allowed_acl: [] };

  componentDidMount = () => {
    getACL()
      .then(data => {
        if (data.forbidden === true) return;
        this.setState({ acl: data.ACL });
      })
      .catch(error => {});
  };

  componentDidUpdate = () => {
    if (this.props.user && !this.state.user) {
      const user = this.props.user;
      this.setState({
        edit: true,
        user: user,
        name: user.name,
        username: user.username,
        allowed_acl: user.acl || []
      });
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = () => {
    const edit = this.state.edit;
    const name = this.state.name;
    const username = this.state.username;
    const password = this.state.password;
    const passwordConfirm = this.state.password_confirm;

    if (!name || !username) {
      this.setState({
        name_error: !name,
        username_error: !username
      });
      return;
    }

    if (!edit) {
      if (!password || !passwordConfirm) {
        this.setState({
          password_error: !password,
          password_confirm_error: !passwordConfirm
        });
        return;
      }

      if (password.length < 6) {
        showError(this.props, "کلمه عبور باید حداقل 6 حرف باشد");
        return;
      }
      if (password != passwordConfirm) {
        showError(this.props, "کلمه عبور و تکرار آن باید یکسان باشد");
        return;
      }
    }

    this.setState({
      loading: true,
      first_name_error: false,
      last_name_error: false,
      password_current_error: false,
      password_error: false,
      password_confirm_error: false
    });

    const allowedACL = this.state.allowed_acl;

    const user = {
      name,
      username,
      password,
      acl: allowedACL
    };

    if (this.state.edit) user._id = this.state.user._id;

    addAdmin(user)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ذخیره پیش آمد");
          return;
        }

        showInfo(this.props, "کاربر با موفقیت ذخیره گردید");
        if (!this.state.edit && data.id)
          this.setState({
            user: { ...user, id: data.id },
            edit: true
          });
        this.setState({
          loading: false,
          saved: true
        });
      })
      .catch(error => {
        showError(this.props, "اشکالی در ذخیره پیش آمد");
      });
  };

  handlePassword = () => {
    const userId = this.state.user._id;
    const passwordCurrent = this.state.password_current;
    const password = this.state.password;
    const passwordConfirm = this.state.password_confirm;

    if (!passwordCurrent || !password || !passwordConfirm) {
      this.setState({
        password_current_error: !passwordCurrent,
        password_error: !password,
        password_confirm_error: !passwordConfirm
      });
      return;
    }

    if (passwordCurrent.length < 6) {
      showError(this.props, "کلمه عبور فعلی باید حداقل 6 حرف باشد");
      return;
    }
    if (password.length < 6) {
      showError(this.props, "کلمه عبور جدید باید حداقل 6 حرف باشد");
      return;
    }
    if (password != passwordConfirm) {
      showError(this.props, "کلمه عبور جدید و تکرار آن باید یکسان باشد");
      return;
    }

    this.setState({
      loading: true,
      password_current_error: false,
      password_error: false,
      password_confirm_error: false
    });

    changePassword(userId, passwordCurrent, password)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          if (data.message === "password incorrect")
            showError(this.props, "کلمه عبور فعلی نادرست است");
          else showError(this.props, "اشکالی در تغییر کلمه عبور پیش آمد");
          return;
        }

        showInfo(this.props, "کلمه عبور با موفقیت تغییر کرد");
        this.setState({
          loading: false,
          password_current: "",
          password: "",
          password_confirm: ""
        });
      })
      .catch(error => {
        showError(this.props, "اشکالی در تغییر کلمه عبور پیش آمد");
      });
  };

  getACLNamesFromId = (acls, ids) => {
    if (!ids || ids.length === 0) return "";

    ids.sort();
    let names = [];
    ids.forEach(id => {
      const acl = acls.filter(acl => acl.id === id)[0];
      if (acl) names.push(acl.desc);
    });

    return names.join(", ");
  };

  render() {
    const { classes } = this.props;
    const { acl, allowed_acl } = this.state;
    const {
      name,
      username,
      password_current,
      password,
      password_confirm,
      edit,
      name_error,
      username_error,
      password_current_error,
      password_error,
      password_confirm_error,
      sending,
      success,
      fail
    } = this.state;

    if (this.state.saved === true) {
      return <Redirect to="/admins" />;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250
        }
      }
    };

    return (
      <div className={classes.memberScroll}>
        <Grid container direction="column">
          <InputRow
            id="name"
            value={name}
            onChange={this.handleChange}
            label="نام"
            classes={classes}
            error={name_error}
          />
          <InputRow
            id="username"
            value={username}
            onChange={this.handleChange}
            label="نام کاربری"
            classes={classes}
            error={username_error}
          />
          {!edit && (
            <>
              <InputRow
                id="password"
                value={password}
                onChange={this.handleChange}
                label="کلمه عبور"
                classes={classes}
                type="password"
                error={password_error}
              />
              <InputRow
                id="password_confirm"
                value={password_confirm}
                onChange={this.handleChange}
                label="تکرار کلمه عبور"
                classes={classes}
                type="password"
                error={password_confirm_error}
              />
            </>
          )}
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={1}
            style={{ marginTop: "8px" }}
          >
            <Grid item>
              <Select
                multiple
                value={allowed_acl}
                onChange={this.handleChange}
                input={
                  <OutlinedInput fullWidth margin="dense" name="allowed_acl" />
                }
                renderValue={selected =>
                  selected && selected.length > 0
                    ? `${selected.length} :تعداد دسترسی انتخابی` //this.getACLNamesFromId(acl, selected) //selected.join(", ")
                    : "...انتخاب دسترسی"
                }
                //MenuProps={MenuProps}
                displayEmpty
                //style={{ maxWidth: 250, textAlign: "right" }}
              >
                {acl.map((acl, index) => (
                  <MenuItem key={index} value={acl.id}>
                    <ListItemText
                      primary={acl.desc}
                      style={{ textAlign: "right" }}
                    />
                    <Checkbox checked={allowed_acl.indexOf(acl.id) > -1} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography align="left" className={classes.label}>
                :دسترسی های مجاز
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Link to="/admins" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                بازگشت
              </MyButton>
            </Link>
          </Grid>
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
            >
              ذخیره
            </MyButton>
          </Grid>
        </Grid>

        {edit && (
          <>
            <Grid container direction="column" style={{ marginTop: "64px" }}>
              <InputRow
                id="password_current"
                value={password_current}
                onChange={this.handleChange}
                label="کلمه عبور فعلی"
                classes={classes}
                type="password"
                error={password_current_error}
              />
              <InputRow
                id="password"
                value={password}
                onChange={this.handleChange}
                label="کلمه عبور جدید"
                classes={classes}
                type="password"
                error={password_error}
              />
              <InputRow
                id="password_confirm"
                value={password_confirm}
                onChange={this.handleChange}
                label="تکرار کلمه عبور جدید"
                classes={classes}
                type="password"
                error={password_confirm_error}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              spacing={2}
              style={{ marginBottom: "16px" }}
            >
              <Grid item>
                <MyButton
                  className={classes.new}
                  variant="contained"
                  onClick={this.handlePassword}
                >
                  تغییر کلمه عبور
                </MyButton>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewAdmin)
);
