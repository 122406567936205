import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography, Card, Paper } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { getPopularInstruments } from "../../services/popularInstruments";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { Link } from "react-router-dom";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { withSnackbar } from "notistack";
import { initACL } from "../../helpers/acl";

class PopularInstruments extends Component {
  state = {
    popularInstruments: [],
  };

  componentDidMount = async () => {
    this.loadPopularInstruments();
  };

  componentDidUpdate(prevProps, prevState) {
    const result = initACL(this.state.initACL, 0);
    if (result.length > 0) {
      this.setState({
        enableUserInfoButton: result[3],
        initACL: true,
      });
    }
  }

  loadPopularInstruments() {
    this.setState({ replies: [] });
    getPopularInstruments()
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({
          popularInstruments: data,
        });
      })
      .catch((error) => {});
  }

  render() {
    const { classes } = this.props;
    const { popularInstruments } = this.state;

    const InstrumentsColumn = (props) => {
      return (
        <Grid
          container
          item
          xs={3}
          direction="column"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item>
            <Typography className={classes.memberName} variant="h6">
              {props.title}
            </Typography>
          </Grid>
          {props.instruments &&
            props.instruments.map((element, index) =>
              element.instrument_name ? (
                <Grid key={index} item>
                  <Card>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-end"
                      xs={12}
                      style={{ padding: 4 }}
                    >
                      <Grid item>
                        <Typography variant="body2" className={classes.info}>
                          {element.instrument_name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.info}>
                          {element.signal_name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.info}>
                          {element.count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ) : null
            )}
        </Grid>
      );
    };

    return (
      <Grid container direction="row">
        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          <Grid item>
            <Typography variant="h5" className={classes.memberName}>
              هشدارهای محبوب
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={4} /*className={classes.liveContainer}*/
        >
          <InstrumentsColumn
            title="همه"
            instruments={popularInstruments.allPopularAlarms}
            classes={classes}
          />
          <InstrumentsColumn
            title="سی روز اخیر"
            instruments={popularInstruments.monthPopularAlarms}
            classes={classes}
          />
          <InstrumentsColumn
            title="هفت روز اخیر"
            instruments={popularInstruments.weekPopularAlarms}
            classes={classes}
          />
          <InstrumentsColumn
            title="امروز"
            instruments={popularInstruments.dayPopularAlarms}
            classes={classes}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          style={{ marginTop: "64px", marginBottom: "16px" }}
        >
          <Grid item>
            <Typography variant="h5" className={classes.memberName}>
              دیده‌بان‌های محبوب
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={4} /*className={classes.liveContainer}*/
        >
          <InstrumentsColumn
            title="همه"
            instruments={popularInstruments.allWatchers}
            classes={classes}
          />
          <InstrumentsColumn
            title="سی روز اخیر"
            instruments={popularInstruments.monthWatchers}
            classes={classes}
          />
          <InstrumentsColumn
            title="هفت روز اخیر"
            instruments={popularInstruments.weekWatchers}
            classes={classes}
          />
          <InstrumentsColumn
            title="امروز"
            instruments={popularInstruments.dayWatchers}
            classes={classes}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(PopularInstruments));
