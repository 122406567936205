import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import {
  addMember,
  getMemberImages,
  addDefaultImage,
  removeMemberImage,
  getMemberDefaultImagePath,
  defaultMemberImage
} from "../../services/members";
import { Typography } from "@material-ui/core";
import MyButton from "../../components/button";
import { Link } from "react-router-dom";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getPermissionsList } from "../../services/permissions";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import { InputRow } from "../../components/inputRow";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import Dropzone from "../../components/dropzone";
import PlateSelector from "../../components/plateSelector";
import { getPlateEnglishLetter } from "../../constants/plateLetters";
import classNames from "classnames";

const CustomRow = props => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      spacing={1}
      style={{ marginTop: "4px" }}
    >
      <Grid item xs={10}>
        {props.customControl}
      </Grid>
      <Grid item xs={2}>
        <Typography align="left" className={props.classes.label}>
          :{props.label}
        </Typography>
      </Grid>
    </Grid>
  );
};

class NewMember extends Component {
  state = {
    permissions: [],
    first_name: "",
    last_name: "",
    melli_code: "",
    phone_number: "",
    company_name: "",
    plate: "",
    permission_id: "-1"
  };

  componentDidMount = () => {
    getPermissionsList()
      .then(data => {
        if (data.forbidden === true) return;
        this.setState({ permissions: data });
      })
      .catch(error => {});
  };

  componentDidUpdate = () => {
    if (this.props.member && !this.state.member) {
      const member = this.props.member;
      this.setState({
        edit: member.id ? true : false,
        member: member,
        first_name: member.first_name,
        last_name: member.last_name,
        melli_code: member.melli_code,
        phone_number: member.phone_number,
        company_name: member.company_name,
        plate: member.plate,
        permission_id: member.permission_id
      });

      const memberId = member.id;
      getMemberImages(memberId)
        .then(data => {
          //if (!data.result) return;
          this.setState({ image: data[data.length - 1] });
        })
        .catch(error => {});
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = () => {
    const first_name = this.state.first_name;
    const last_name = this.state.last_name;
    if (!first_name || !last_name) {
      this.setState({
        first_name_error: !first_name,
        last_name_error: !last_name
      });
      if (this.props.popup)
        showError(this.props, "فیلدهای لازم را وارد نکرده اید");
      return;
    }

    this.setState({
      loading: true,
      first_name_error: false,
      last_name_error: false
    });

    let plate = null;
    if (APP_MODE == APP_MODES.PLATE) {
      const plate_numbers = this.state.plate_numbers;
      const plate_letter = this.state.plate_letter;
      const left = `${plate_numbers[0]}${plate_numbers[1]}`;
      const letter = getPlateEnglishLetter(plate_letter);
      const right = `${plate_numbers[2]}${plate_numbers[3]}${plate_numbers[4]}`;
      const city = `${plate_numbers[5]}${plate_numbers[6]}`;
      plate = `${left}${letter}${right}${city}`;
      if (plate.indexOf("null") >= 0) plate = null;
    }

    const member = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      melli_code: this.state.melli_code,
      phone_number: this.state.phone_number,
      company_name: this.state.company_name,
      plate: plate,
      permission_id: parseInt(this.state.permission_id)
    };

    if (this.state.edit) member.id = this.state.member.id;
    if (member.permission_id < 0) member.permission_id = null;

    addMember(member)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ذخیره پیش آمد");
          return;
        }

        showInfo(this.props, "کاربر با موفقیت ذخیره گردید");
        if (!this.state.edit && data.id)
          this.setState(
            {
              member: { ...member, id: data.id },
              edit: true
            },
            () =>
              this.props.memberCreated &&
              this.props.memberCreated(this.state.member)
          );
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        showError(this.props, "اشکالی در ذخیره پیش آمد");
      });
  };

  handlePlateChange = (plate_numbers, plate_letter) => {
    this.setState({ plate_numbers, plate_letter });
  };

  onDrop = (vector, preview, base64) => {
    this.setState({
      vector: vector,
      file: { preview: preview, base64: base64 }
    });
  };

  componentWillUnmount = () => {
    if (this.state.file) URL.revokeObjectURL(this.state.file.preview);
  };

  handleUpload = () => {
    let memberId;
    if (this.props.member) memberId = this.props.member.id;
    if (!memberId && this.state.edit) memberId = this.state.member.id;

    if (!memberId) {
      showError(
        this.props,
        "ابتدا کاربر جدید را ذخیره کنید سپس عکس را بارگذاری نمایید"
      );
      return;
    }

    this.setState({
      uploading: true
    });

    const base64 = this.state.file.base64;

    addDefaultImage(memberId, base64)
      .then(data => {
        if (data.result === false) {
          showError(this.props, "اشکالی در بارگذاری پیش آمد");
          return;
        }
        showInfo(this.props, "تصویر جدید با موفقیت بارگذاری شد");
        if (this.state.file) URL.revokeObjectURL(this.state.file.preview);
        this.setState({
          uploading: false,
          file: null,
          newImage: base64
        });
      })
      .catch(error => {});
  };

  render() {
    const { classes, popup } = this.props;
    const { permissions } = this.state;
    const {
      first_name,
      last_name,
      melli_code,
      phone_number,
      company_name,
      plate,
      permission_id,
      first_name_error,
      last_name_error,
      image,
      file,
      uploading,
      sending,
      success,
      fail
    } = this.state;

    return (
      <div /*className={classes.memberScroll}*/
        className={classNames({
          [classes.memberScroll]: !popup,
          [classes.memberScrollNoPadding]: popup === true
        })}
      >
        <Grid container direction="column">
          <InputRow
            id="first_name"
            value={first_name}
            onChange={this.handleChange}
            label="نام"
            classes={classes}
            error={first_name_error}
          />
          <InputRow
            id="last_name"
            value={last_name}
            onChange={this.handleChange}
            label="نام خانوادگی"
            classes={classes}
            error={last_name_error}
          />
          <InputRow
            id="melli_code"
            value={melli_code}
            onChange={this.handleChange}
            label="کد ملی"
            classes={classes}
          />
          <InputRow
            id="phone_number"
            value={phone_number}
            onChange={this.handleChange}
            label="شماره تلفن"
            classes={classes}
          />
          <InputRow
            id="company_name"
            value={company_name}
            onChange={this.handleChange}
            label="نام شرکت"
            classes={classes}
          />
          {APP_MODE == APP_MODES.PLATE && (
            /*<InputRow
              id="plate"
              value={plate}
              onChange={this.handleChange}
              label="پلاک"
              classes={classes}
            />*/
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
              style={{ marginTop: "8px" }}
            >
              <Grid item style={{ maxWidth: "300px" }}>
                <PlateSelector
                  onPlateChange={this.handlePlateChange}
                  default={plate}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography align="left" className={classes.label}>
                  :پلاک
                </Typography>
              </Grid>
            </Grid>
          )}
          <CustomRow
            label="مجوز"
            classes={classes}
            customControl={
              <Select
                value={permission_id}
                onChange={this.handleChange}
                inputProps={{
                  name: "permission_id"
                }}
                input={<OutlinedInput fullWidth margin="dense" />}
                style={{ textAlign: "right" }}
              >
                <MenuItem value="-1" style={{ direction: "rtl" }}>
                  <em>ندارد</em>
                </MenuItem>
                {permissions.map((permission, index) => (
                  <MenuItem
                    key={index}
                    value={permission.id.toString()}
                    style={{ direction: "rtl" }}
                  >
                    {permission.name}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          {APP_MODE == APP_MODES.PLATE && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={2}
              style={{ marginTop: "8px" }}
            >
              <Grid item>
                <Dropzone hasId={true} getFaces={false} onDrop={this.onDrop} />
              </Grid>
              {file && (
                <Grid item>
                  <MyButton
                    className={classes.upload}
                    variant="contained"
                    onClick={this.handleUpload}
                    disabled={uploading}
                  >
                    بارگذاری تصویر جدید
                  </MyButton>
                </Grid>
              )}
              {file && (
                <Grid item>
                  <img
                    src={file.preview}
                    className={classes.imagePreview}
                    alt=""
                  />
                </Grid>
              )}
              {this.state.member && !this.state.newImage && (
                <Grid item>
                  <img
                    src={getMemberDefaultImagePath(
                      `${this.state.member.id}.jpg`
                    )}
                    className={classes.memberEditImagePlate}
                    alt=""
                  />
                </Grid>
              )}
              {this.state.newImage && (
                <Grid item>
                  <img
                    src={this.state.newImage}
                    className={classes.memberEditImagePlate}
                    alt=""
                  />
                </Grid>
              )}
              <Grid item xs={2}></Grid>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" justify="center" spacing={2}>
          {!popup && (
            <Grid item>
              <Link to="/members" style={{ textDecoration: "none" }}>
                <MyButton className={classes.new} variant="contained">
                  بازگشت
                </MyButton>
              </Link>
            </Grid>
          )}
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
            >
              ذخیره
            </MyButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewMember)
);
