export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user == null || user == undefined || user == "undefined") return null;
  return JSON.parse(user);
};

export const setUser = user => {
  if (user != null && user != undefined && user != "undefined")
    localStorage.setItem("user", JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem("user");
};

export const hasUser = () => {
  const user = localStorage.getItem("user");
  const hasUser = user != null && user != undefined && user != "undefined";
  return hasUser;
};
