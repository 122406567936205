import { REPORT_FACE_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const reportByImage = (
  vector,
  maxDistance,
  commonSearchParams,
  page,
  pageSize
) => {
  return myFetch(`${REPORT_FACE_ENDPOINT}/image`, "POST", {
    vector: vector,
    maxDistance: maxDistance,
    commonSearchParams: commonSearchParams,
    page: page,
    pageSize: pageSize
  });
};

export const reportByMember = (
  memberId,
  minConf,
  commonSearchParams,
  page,
  pageSize
) => {
  return myFetch(`${REPORT_FACE_ENDPOINT}/member`, "POST", {
    memberId: memberId,
    minConf: minConf,
    commonSearchParams: commonSearchParams,
    page: page,
    pageSize: pageSize
  });
};

export const getReportImages1 = async (camId, epoch, faceId) => {
  let response = await myFetch(
    `${REPORT_FACE_ENDPOINT}/image/${camId}/${epoch}/${faceId}`,
    "GET"
  );

  let data = await response.json();
  //console.log("response:", data);
  if (data.result) return `data:image/jpeg;base64,${data.base64}`;
  return null;
};

export const getReportImages2 = (camId, epoch, faceId) => {
  const url = `http://192.168.1.37:8002/get_face_image?cam_id=${camId}&epoch=${epoch}&face_id=${faceId}`;
  myFetch(`${REPORT_FACE_ENDPOINT}/image/${camId}/${epoch}/${faceId}`, "GET")
    .then(data => {
      //console.log("getReportImages:", data.result);
      if (data.result) return `data:image/jpeg;base64,${data.base64}`;
      return null;
    })
    .catch(error => {
      return null;
    });

  /*{"result": false, "error": "NOT_FOUND"}
or
{"result": true, "base64":""}*/
};

export const getReportImages = (camId, epoch, faceId) => {
  return myFetch(
    `${REPORT_FACE_ENDPOINT}/image/${camId}/${epoch}/${faceId}`,
    "GET"
  );
};
