import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import { Typography } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { getStats } from "../../services/stats";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { withSnackbar } from "notistack";
import { Redirect } from "react-router-dom";

class Summary extends Component {
  state = {
    stats: {
      allUsers: 0,
      todayNewUsers: 0,
      todayActiveUsers: 0,
      yesterdayNewUsers: 0,
      yesterdayActiveUsers: 0,
      membershipUsers: 0,
      allPurchaseCount: 0,
      allPurchaseAmount: 0,
      todayPurchaseCount: 0,
      todayPurchaseAmount: 0,
      yesterdayPurchaseCount: 0,
      yesterdayPurchaseAmount: 0,
      membershipUsers: 0,
      onlyOneMembershipUsers: 0,
      moreThanOneMembershipUsers: 0,
      monthPurchaseAmount: {},
      month1PurchaseAmount: {},
      month2PurchaseAmount: {},
      month3PurchaseAmount: {},
      skuPurchases: [],
      allCreditCount: 0,
      allCreditAmount: 0,
      todayCreditCount: 0,
      todayCreditAmount: 0,
      allFilterPurchaseCount: 0,
      allFilterPurchaseAmount: 0,
      todayFilterPurchaseCount: 0,
      todayFilterPurchaseAmount: 0,
      allStrategyPurchaseCount: 0,
      allStrategyPurchaseAmount: 0,
      todayStrategyPurchaseCount: 0,
      todayStrategyPurchaseAmount: 0,
      allCreditProfitAmount: 0,
      todayCreditProfitAmount: 0,
    },
  };

  componentDidMount = async () => {
    getStats()
      .then((data) => {
        if (data.forbidden === true) {
          //showError(this.props, "عملیات غیر مجاز");
          this.setState({ redirect: true });
          return;
        }
        this.setState({ stats: data });
      })
      .catch((error) => {});
  };

  render() {
    const { classes } = this.props;
    const { liveLoaded, selectedCameraId } = this.state;
    const { cameras, captures, capturesCount, src } = this.props;

    if (this.state.redirect === true) {
      return <Redirect to="/contacts" />;
    }

    return (
      <Grid container direction="row" /*className={classes.liveContainer}*/>
        <Grid container spacing={4} className={classes.stats}>
          <Grid item xs={6} md={4}>
            <DetailCard
              title="کاربران فعال امروز"
              number={this.state.stats.todayActiveUsers}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DetailCard
              title="کاربران جدید امروز"
              number={this.state.stats.todayNewUsers}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DetailCard title="کل کاربران" number={this.state.stats.allUsers} />
          </Grid>
          <Grid item xs={6} md={4}>
            <DetailCard
              title="کاربران فعال دیروز که امروز فعال نبودند"
              number={this.state.stats.yesterdayActiveUsers}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DetailCard
              title="کاربران جدید دیروز"
              number={this.state.stats.yesterdayNewUsers}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          className={classes.stats}
          style={{ marginTop: 48 }}
        >
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ فروش امروز"
              number={this.state.stats.todayPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد فروش امروز"
              number={this.state.stats.todayPurchaseCount}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ فروش کل"
              number={this.state.stats.allPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد فروش کل"
              number={this.state.stats.allPurchaseCount}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ فروش دیروز"
              number={this.state.stats.yesterdayPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد فروش دیروز"
              number={this.state.stats.yesterdayPurchaseCount}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          className={classes.stats}
          style={{ marginTop: 48 }}
        >
          <Grid item xs={6} md={4}>
            <DetailCard
              title="تعداد کاربران با بیشتر از یک خرید"
              number={this.state.stats.moreThanOneMembershipUsers}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DetailCard
              title="تعداد کاربران با یک خرید"
              number={this.state.stats.onlyOneMembershipUsers}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DetailCard
              title="تعداد کاربران فعلی دارای اشتراک"
              number={this.state.stats.membershipUsers}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          className={classes.stats}
          style={{ marginTop: 48 }}
        >
          {this.state.stats.month3PurchaseAmount &&
            this.state.stats.month3PurchaseAmount.count && (
              <Grid item xs={6} md={3}>
                <DetailCard
                  title={`فروش ${this.state.stats.month3PurchaseAmount.month}`}
                  number={this.state.stats.month3PurchaseAmount.count.toLocaleString()}
                  number2={this.state.stats.month3PurchaseAmount.totalAmount.toLocaleString()}
                />
              </Grid>
            )}
          {this.state.stats.month2PurchaseAmount &&
            this.state.stats.month2PurchaseAmount.count && (
              <Grid item xs={6} md={3}>
                <DetailCard
                  title={`فروش ${this.state.stats.month2PurchaseAmount.month}`}
                  number={this.state.stats.month2PurchaseAmount.count.toLocaleString()}
                  number2={this.state.stats.month2PurchaseAmount.totalAmount.toLocaleString()}
                />
              </Grid>
            )}
          {this.state.stats.month1PurchaseAmount &&
            this.state.stats.month1PurchaseAmount.count && (
              <Grid item xs={6} md={3}>
                <DetailCard
                  title={`فروش ${this.state.stats.month1PurchaseAmount.month}`}
                  number={this.state.stats.month1PurchaseAmount.count.toLocaleString()}
                  number2={this.state.stats.month1PurchaseAmount.totalAmount.toLocaleString()}
                />
              </Grid>
            )}
          {this.state.stats.monthPurchaseAmount &&
            this.state.stats.monthPurchaseAmount.count && (
              <Grid item xs={6} md={3}>
                <DetailCard
                  title={`فروش ${this.state.stats.monthPurchaseAmount.month}`}
                  number={this.state.stats.monthPurchaseAmount.count.toLocaleString()}
                  number2={this.state.stats.monthPurchaseAmount.totalAmount.toLocaleString()}
                />
              </Grid>
            )}
        </Grid>

        {/*<Grid
          container
          spacing={2}
          className={classes.stats}
          style={{ marginTop: 48 }}
        >
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مقدار افزایش اعتبار امروز"
              number={this.state.stats.todayCreditAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد افزایش اعتبار امروز"
              number={this.state.stats.todayCreditCount}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مقدار افزایش اعتبار کل"
              number={this.state.stats.allCreditAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد افزایش اعتبار کل"
              number={this.state.stats.allCreditCount}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ خرید فیلتر امروز"
              number={this.state.stats.todayFilterPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد خرید فیلتر امروز"
              number={this.state.stats.todayFilterPurchaseCount}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ خرید فیلتر کل"
              number={this.state.stats.allFilterPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد خرید فیلتر کل"
              number={this.state.stats.allFilterPurchaseCount}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ خرید استراتژی امروز"
              number={this.state.stats.todayStrategyPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد خرید استراتژی امروز"
              number={this.state.stats.todayStrategyPurchaseCount}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ خرید استراتژی کل"
              number={this.state.stats.allStrategyPurchaseAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="تعداد خرید استراتژی کل"
              number={this.state.stats.allStrategyPurchaseCount}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ سود امروز"
              number={this.state.stats.todayCreditProfitAmount.toLocaleString()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DetailCard
              title="مبلغ سود کل"
              number={this.state.stats.allCreditProfitAmount.toLocaleString()}
            />
          </Grid>
            </Grid>*/}

        <Grid
          container
          spacing={1}
          className={classes.stats}
          style={{ marginTop: 48 }}
        >
          {this.state.stats.skuPurchases &&
            this.state.stats.skuPurchases.map((skuPurchase, index) => (
              <Grid key={index} item xs={6} sm={3} md={2}>
                <DetailCard
                  title={skuPurchase._id}
                  number={skuPurchase.count.toLocaleString()}
                  number2={skuPurchase.totalAmount.toLocaleString()}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Summary));
