import { TEMPLATE_ANSWER_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getTemplateAnswer = (templateAnswerId) => {
  return myFetch(`${TEMPLATE_ANSWER_ENDPOINT}/${templateAnswerId}`, "GET");
};

export const getTemplateAnswers = () => {
  return myFetch(`${TEMPLATE_ANSWER_ENDPOINT}`, "GET");
};

export const addTemplateAnswer = (templateAnswer) => {
  return templateAnswer._id
    ? myFetch(`${TEMPLATE_ANSWER_ENDPOINT}`, "PUT", {
        templateAnswer: templateAnswer,
      })
    : myFetch(`${TEMPLATE_ANSWER_ENDPOINT}`, "POST", {
        templateAnswer: templateAnswer,
      });
};

export const removeTemplateAnswer = (templateAnswerId) => {
  return myFetch(`${TEMPLATE_ANSWER_ENDPOINT}`, "DELETE", {
    templateAnswerId: templateAnswerId,
  });
};
