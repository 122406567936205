import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import { showInfo, showError } from "../helpers/notification";
import MyButton from "../components/button";
import { InputRow } from "../components/inputRow";
import { Link, Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import stylesMembers from "../styles/members";
import { withSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import { addNotif } from "../services/notif";

class NewNotif extends Component {
  state = {};

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  TYPE_PUBLIC = 1;
  TYPE_PERSONAL = 2;
  TYPE_EDUCATIONAL = 3;

  handleSave = () => {
    const title = this.state.title;
    const content = this.state.content;
    if (!title || !content) {
      this.setState({
        title_error: !title,
        content_error: !content,
      });
      showError(this.props, "فیلدهای لازم را وارد نکرده اید");
      return;
    }

    this.setState({
      loading: true,
      title_error: false,
      content_error: false,
    });

    const topic = parseInt(this.state.topic);
    const membership = this.state.membership;

    const notif = {
      secret: "12FVbm756_)df^",
      title,
      content,
      inbox: this.state.inbox,
      alert: this.state.alert,
      rate: this.state.rate,
      user_id: this.state.user_id,
      topic,
      membership,
    };

    console.log("notif", notif);

    addNotif(notif)
      .then((data) => {
        this.setState({ loading: false });
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ارسال نوتیف پیش آمد");
          return;
        }

        showInfo(this.props, "نوتیف با موفقیت ارسال گردید");

        this.setState({ loading: true });
        this.setState({
          saved: true,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        showError(this.props, "اشکالی در ارسال نوتیف پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const { member } = this.state;
    const {
      loading,
      user_id,
      title,
      content,
      inbox,
      alert,
      rate,
      topic,
      membership,
      title_error,
      content_error,
      image,
      file,
      uploading,
      sending,
      success,
      fail,
    } = this.state;

    /*if (this.state.saved === true) {
      return <Redirect to="/inbox" />;
    }*/

    return (
      <>
        <Grid container direction="column">
          <InputRow
            id="user_id"
            value={user_id}
            onChange={this.handleChange}
            label="user_id"
            classes={classes}
          />
          <InputRow
            id="title"
            value={title}
            onChange={this.handleChange}
            label="title"
            classes={classes}
            error={title_error}
          />
          <InputRow
            id="content"
            value={content}
            onChange={this.handleChange}
            label="content"
            classes={classes}
            error={content_error}
            multiline={true}
            rows="3"
          />
          <InputRow
            id="inbox"
            value={inbox}
            onChange={this.handleChange}
            label="inbox"
            classes={classes}
          />
          <InputRow
            id="alert"
            value={alert}
            onChange={this.handleChange}
            label="alert"
            classes={classes}
            multiline={true}
            rows="3"
          />
          <InputRow
            id="rate"
            value={rate}
            onChange={this.handleChange}
            label="rate"
            classes={classes}
          />
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={10}>
              <RadioGroup
                row={true}
                id="topic"
                aria-label="topic"
                name="topic"
                className={classes.radioGroup}
                value={topic}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="secondary" />}
                  label="all"
                  labelPlacement="start"
                  className={classes.label}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="secondary" />}
                  label="ipo"
                  labelPlacement="start"
                  className={classes.label}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={2}>
              <Typography align="left" className={classes.label}>
                :topic
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
            spacing={3}
          >
            <Grid item xs={10} style={{ textAlign: "right" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.handleCheck}
                    name="membership"
                    checked={membership}
                  />
                }
                label="ارسال فقط برای کاربران دارای اشتراک"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography align="left" className={classes.label}></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          spacing={4}
          style={{ marginTop: 16 }}
        >
          <Grid item>
            <Link to="/inbox" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                بازگشت
              </MyButton>
            </Link>
          </Grid>

          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
              disabled={loading}
            >
              ارسال
            </MyButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-end"
          spacing={1}
          style={{ marginTop: 32 }}
        >
          <Typography align="right" className={classes.label}>
            :مثال
          </Typography>
          <Typography align="right" className={classes.label}>
            هشدار عرضه اولیه
          </Typography>
          <Typography align="right" className={classes.label}>
            ثبت سفارش خرید برای عرضه اولیه نماد ومهان از ساعت 7:30 تا 11:30 با
            قیمت 5625 و حداکثر تعداد 500 سهم امکان‌پذیر می‌باشد
          </Typography>
          <Typography align="right" className={classes.label}>
            ----------
          </Typography>
          <Typography align="right" className={classes.label}>
            اطلاع‌رسانی
          </Typography>
          <Typography align="right" className={classes.label}>
            کاربر گرامی، نمایش عرضه و تقاضا و حجم معاملات از طرف هسته معاملات با
            تاخیر انجام می‌شود. لطفاً تا برطرف شدن مشکل از سایت فناوری بورس
            استفاده نمایید
          </Typography>
          <Typography align="right" className={classes.label}>
            ----------
          </Typography>
          <Typography align="right" className={classes.label}>
            هشدار وضعیت بازار
          </Typography>
          <Typography align="right" className={classes.label}>
            با توجه به رفتار معامله‌گران مراقب اصلاح بازار باشید
          </Typography>
        </Grid>
      </>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewNotif)
);
