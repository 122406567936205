import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import BoxTitle from "../components/boxTitle";
import TemplateAnswersContainer from "../containers/templateAnswer/templateAnswers";

class TemplateAnswers extends Component {
  render() {
    const { classes } = this.props;
    return <TemplateAnswersContainer />;
  }
}

export default withStyles(styles)(TemplateAnswers);
