import store from "../store";

export const verifyACL = (userACL, name, action) => {
  console.log(`verifyACL -> Name: ${name} , Action: ${action}`);

  const state = store.getState();
  const ACL = state.acl.ACL;

  let aclName = name;
  if (action) aclName = `${name}_${action}`;
  const requiredId = ACL.filter((acl) => acl.name === aclName)[0].id;

  //console.log("verifyACL");
  console.log(userACL);
  console.log(requiredId);
  console.log(aclName);
  console.log("ACCESS: ", userACL && userACL.includes(requiredId));
  console.log("-------------------------------------------------");

  if (userACL && userACL.includes(requiredId)) return true;
  return false;
};

export const initACL = (initACL, type) => {
  if (initACL) return [];
  const state = store.getState();
  const user = state.user;
  if (!user.acl) return [];
  const acl = state.acl;
  const ACL_Names = acl.ACL_Names;
  const ACL_Actions = acl.ACL_Actions;

  let name;
  switch (type) {
    case 0:
      name = ACL_Names.USER;
      break;
    case 1:
      name = ACL_Names.INBOX;
      break;
    case 2:
      name = ACL_Names.CONTACT;
      break;
    case 3:
      name = ACL_Names.FAQ;
      break;
    case 4:
      name = ACL_Names.DATA;
      break;
  }

  return [
    verifyACL(user.acl, name, ACL_Actions.EDIT),
    verifyACL(user.acl, name, ACL_Actions.DELETE),
    verifyACL(user.acl, name, ACL_Actions.ADD),
    verifyACL(user.acl, name, ACL_Actions.VIEW),
  ];
};
