import { SETTINGS_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getDateTime = () => {
  return myFetch(`${SETTINGS_ENDPOINT}/time`, "GET");
};

export const getServer = async () => {
  //return myFetch(`${SETTINGS_ENDPOINT}/server`, "GET");

  let response = await myFetch(`${SETTINGS_ENDPOINT}/server`, "GET");
  let data = await response; //.json();
  return data;

  /*const fetchAsyncA = async () => 
  await (await fetch('https://api.github.com')).json()
  
  await fetch(`https://api.github.com/users/${name}`).then(async (response)=> {
    return await response.json()*/
};

export const getSettings = async () => {
  let response = await myFetch(`${SETTINGS_ENDPOINT}`, "GET");
  let data = await response; //.json();
  return data;

  // unknown_conf_max = 0.6;
  // alarm_conf_min = 0.9;
};
