import { getUser, removeUser } from "./user";
import { removeUser as removeUserAction } from "../reducers/user";
import store from "../store";

export const myFetch = async (url, method, body) => {
  const user = getUser();
  const token = user ? user.token : undefined;

  const params = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  if (body && method == "GET") {
    const queries = new URLSearchParams(body);
    url = url + "?" + queries;
  } else if (body) params.body = JSON.stringify(body);

  //return timeout(5000, fetch(url, params));
  //return fetch2(url, params, 5000);

  //return fetch(url, params);

  return new Promise(function(resolve, reject) {
    fetch(url, params)
      .then(response => {
        if (response.status == 401) {
          //console.log("INVALID_TOKEN");
          removeUser();
          store.dispatch(removeUserAction());
          reject("INVALID_TOKEN");
        }
        return response.json();
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject("Network error!");
      });
  });
};

function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      reject(new Error("timeout"));
    }, ms);
    promise.then(resolve, reject);
  });
}

function fetch2(url, options, timeout = 7000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    )
  ]);
}
