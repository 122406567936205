import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography } from "@material-ui/core";
import MyButton from "../../components/button";
import { Link } from "react-router-dom";
import ConfirmDialog from "../../components/dialog";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import {
  getTemplateAnswers,
  removeTemplateAnswer,
} from "../../services/templateAnswers";
import { initACL } from "../../helpers/acl";

class TemplateAnswers extends Component {
  state = {
    templateAnswers: [],
    deleteDialog: false,
  };

  componentDidMount = () => {
    getTemplateAnswers()
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ templateAnswers: data });
      })
      .catch((error) => {});
  };

  handleRemove = (templateAnswerId) => {
    removeTemplateAnswer(templateAnswerId)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        showInfo(this.props, "جواب آماده با موفقیت حذف گردید");
        this.setState({
          templateAnswers: this.state.templateAnswers.filter(
            (per) => per._id !== templateAnswerId
          ),
        });
      })
      .catch((error) => {});
  };

  openDeleteDialog = (templateAnswerId) =>
    this.setState({ deleteDialog: true, deleteId: templateAnswerId });

  closeDeleteDialog = () => this.setState({ deleteDialog: false, deleteId: 0 });

  handleDeleteDialogConfirm = () => {
    this.handleRemove(this.state.deleteId);
    this.closeDeleteDialog();
  };

  render() {
    const { classes } = this.props;
    const { templateAnswers } = this.state;
    const {
      enableEditButton,
      enableDeleteButton,
      enableAddButton,
      first_name,
      last_name,
      sending,
      success,
      fail,
    } = this.state;

    return (
      <div /*className={classes.memberScroll}*/>
        <ConfirmDialog
          title="حذف جواب آماده"
          content="آیا از حذف مطمئن هستید؟"
          open={this.state.deleteDialog}
          handleConfirm={this.handleDeleteDialogConfirm}
          handleCancel={this.closeDeleteDialog}
        />

        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          style={{ marginBottom: "16px" }}
        >
          <Grid item>
            <Link to="/templateAnswers/new" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                جواب آماده جدید
              </MyButton>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row-reverse"
          spacing={3}
          style={{ marginBottom: "16px" }}
        >
          {templateAnswers.map((templateAnswer, index) => (
            <Grid key={index} item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className={classes.memberContent}
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item container direction="column" xs={1} spacing={1}>
                    <Grid item>
                      <Link to={`/templateAnswers/${templateAnswer._id}`}>
                        <img
                          src={require(`../../images/icon_edit.png`)}
                          className={classes.editIcon}
                          title="ویرایش جواب آماده"
                        />
                      </Link>
                    </Grid>

                    <Grid item>
                      <img
                        src={require(`../../images/icon_delete.png`)}
                        className={classes.editIcon}
                        onClick={() =>
                          this.openDeleteDialog(templateAnswer._id)
                        }
                        title="حذف جواب آماده"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    xs={11}
                    spacing={1}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", color: "DarkBlue" }}
                        >
                          {`${templateAnswer.title}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :عنوان
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {`${templateAnswer.answer}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :پاسخ
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item xs={3}>
                  <img
                    src={require(`../../images/icon_user.png`)}
                    className={classes.memberImage}
                    alt=""
                  />
                        </Grid>*/}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {enableAddButton && (
          <Link to="/templateAnswers/new" style={{ textDecoration: "none" }}>
            <MyButton className={classes.new} variant="contained">
              ثبت جواب آماده جدید
            </MyButton>
          </Link>
        )}
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(TemplateAnswers)
);
