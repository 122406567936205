import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography } from "@material-ui/core";
import MyButton from "../../components/button";
import { Link } from "react-router-dom";
import ConfirmDialog from "../../components/dialog";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import { getAdmins, removeAdmin } from "../../services/admins";
import { initACL } from "../../helpers/acl";

class Admins extends Component {
  state = {
    admins: [],
    deleteDialog: false
  };

  componentDidMount = () => {
    getAdmins()
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ admins: data });
      })
      .catch(error => {});
  };

  componentDidUpdate = () => {
    const result = initACL(this.state.initACL, 3);
    if (result.length > 0) {
      this.setState({
        enableEditButton: result[0],
        enableDeleteButton: result[1],
        enableAddButton: result[2],
        initACL: true
      });
    }
  };

  handleRemove = userId => {
    removeAdmin(userId)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        showInfo(this.props, "کاربر با موفقیت حذف گردید");
        this.setState({
          admins: this.state.admins.filter(per => per._id !== userId)
        });
      })
      .catch(error => {});
  };

  openDeleteDialog = userId =>
    this.setState({ deleteDialog: true, deleteId: userId });

  closeDeleteDialog = () => this.setState({ deleteDialog: false, deleteId: 0 });

  handleDeleteDialogConfirm = () => {
    this.handleRemove(this.state.deleteId);
    this.closeDeleteDialog();
  };

  render() {
    const { classes } = this.props;
    const { admins } = this.state;
    const {
      enableEditButton,
      enableDeleteButton,
      enableAddButton,
      first_name,
      last_name,
      sending,
      success,
      fail
    } = this.state;

    return (
      <div /*className={classes.memberScroll}*/>
        <ConfirmDialog
          title="حذف کاربر"
          content="آیا از حذف مطمئن هستید؟"
          open={this.state.deleteDialog}
          handleConfirm={this.handleDeleteDialogConfirm}
          handleCancel={this.closeDeleteDialog}
        />

        <Grid container direction="row-reverse" spacing={3}>
          {admins.map((user, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className={classes.memberContent}
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={9}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item container direction="column" xs={2} spacing={1}>
                    {enableEditButton && (
                      <Grid item>
                        <Link to={`/admins/${user._id}`}>
                          <img
                            src={require(`../../images/icon_edit.png`)}
                            className={classes.editIcon}
                            title="ویرایش کاربر"
                          />
                        </Link>
                      </Grid>
                    )}
                    {enableDeleteButton && (
                      <Grid item>
                        <img
                          src={require(`../../images/icon_delete.png`)}
                          className={classes.editIcon}
                          onClick={() => this.openDeleteDialog(user._id)}
                          title="حذف کاربر"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    xs={10}
                    spacing={1}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography className={classes.memberName}>
                          {`${user.name}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.memberName}>
                          :نام
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography className={classes.memberName}>
                          {`${user.username}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.memberName}>
                          :نام کاربری
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={require(`../../images/icon_user.png`)}
                    className={classes.memberImage}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {enableAddButton && (
          <Link to="/admins/new" style={{ textDecoration: "none" }}>
            <MyButton className={classes.new} variant="contained">
              ثبت کاربر جدید
            </MyButton>
          </Link>
        )}
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(Admins)
);
