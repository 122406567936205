import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  card: {
    //maxWidth: 400
  },
  divider: {
    margin: 8, //theme.spacing.unit * 2
  },
});

class DetailCard extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography variant="subtitle1" color="textSecondary" align="right">
            {this.props.title}
          </Typography>

          <Divider variant="middle" className={classes.divider} />

          <Typography component="h4" variant="h4" align="left">
            {this.props.number}
          </Typography>

          {this.props.number2 && (
            <Typography
              component="h5"
              variant="h5"
              align="left"
              style={{ marginTop: 8 }}
            >
              {this.props.number2}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
}

DetailCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailCard);
