export const showInfo = (props, message) => {
  props.enqueueSnackbar(message, {
    variant: "info"
  });
};

export const showError = (props, message) => {
  props.enqueueSnackbar(message, {
    variant: "error"
  });
};
