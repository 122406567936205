import { INBOX_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

/*export const getInbox = () => {
  return myFetch(`${INBOX_ENDPOINT}`, "GET");
};*/

export const getInbox = userId => {
  return myFetch(`${INBOX_ENDPOINT}/${userId}`, "GET");
};

export const addInbox = inbox => {
  return inbox.id
    ? myFetch(`${INBOX_ENDPOINT}`, "PUT", inbox)
    : myFetch(`${INBOX_ENDPOINT}`, "POST", inbox);
};

export const getInboxImagePath = fileName => {
  return `${INBOX_ENDPOINT}/image/${fileName}`;
};
