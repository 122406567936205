import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography, Card, Paper } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { deleteCache } from "../../services/redis";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { Link } from "react-router-dom";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { withSnackbar } from "notistack";
import { initACL } from "../../helpers/acl";
import MyButton from "../../components/button";

class Redis extends Component {
  state = {};

  componentDidMount = async () => {};

  componentDidUpdate(prevProps, prevState) {
    const result = initACL(this.state.initACL, 0);
    if (result.length > 0) {
      this.setState({
        enableUserInfoButton: result[3],
        initACL: true,
      });
    }
  }

  deleteRedisCache() {
    deleteCache()
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        showInfo(this.props, "کش با موفقیت پاک شد");
      })
      .catch((error) => {
        showError(this.props, "اشکالی در پاک کردن کش پیش آمد");
      });
  }

  render() {
    const { classes } = this.props;
    const { replies, enableUserInfoButton } = this.state;
    const { cameras, captures, capturesCount, src } = this.props;

    return (
      <Grid container direction="row" /*className={classes.liveContainer}*/>
        <Grid container justify="center" style={{ marginTop: 32 }}>
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={() => this.deleteRedisCache()}
            >
              Redis پاک کردن کش
            </MyButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Redis));
