import { MEMBER_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getMember = memberId => {
  return myFetch(`${MEMBER_ENDPOINT}/${memberId}`, "GET");
};

export const getMembers = () => {
  return myFetch(`${MEMBER_ENDPOINT}`, "GET");
};

export const getMembersList = () => {
  return myFetch(`${MEMBER_ENDPOINT}/list`, "GET");
};

export const addMember = member => {
  return member.id
    ? myFetch(`${MEMBER_ENDPOINT}`, "PUT", {
        member: member
      })
    : myFetch(`${MEMBER_ENDPOINT}`, "POST", {
        member: member
      });
};

export const removeMember = memberId => {
  return myFetch(`${MEMBER_ENDPOINT}`, "DELETE", {
    memberId: memberId
  });
};

export const getMemberImages = memberId => {
  return myFetch(`${MEMBER_ENDPOINT}/images/${memberId}`, "GET");
};

export const addMemberImage = (memberId, image, vector) => {
  return myFetch(`${MEMBER_ENDPOINT}/images`, "POST", {
    memberId: memberId,
    image: image,
    vector: vector
  });
};

export const addDefaultImage = (memberId, image) => {
  return myFetch(`${MEMBER_ENDPOINT}/images/default`, "POST", {
    memberId: memberId,
    image: image
  });
};

export const removeMemberImage = (memberId, imageId, fileName) => {
  return myFetch(`${MEMBER_ENDPOINT}/images`, "DELETE", {
    memberId: memberId,
    imageId: imageId,
    fileName: fileName
  });
};

export const defaultMemberImage = (memberId, imageId) => {
  return myFetch(`${MEMBER_ENDPOINT}/images`, "PATCH", {
    memberId: memberId,
    imageId: imageId
  });
};

export const getFaces = base64 => {
  /*return Promise.resolve({
    ok: true,
    status: 400,
    json: () => Promise.resolve({ result: [{ vector: [1, 2, 3] }] })
  });*/

  return myFetch(`${MEMBER_ENDPOINT}/faces`, "POST", {
    base64: base64
  });
};

export const trainModel = () => {
  /*return Promise.resolve({
    ok: true,
    status: 400,
    json: () => [{ ok: true }]
  });*/

  return myFetch(`${MEMBER_ENDPOINT}/train`, "POST");
};

export const getMemberImagePath = (memberId, fileName) => {
  return `${MEMBER_ENDPOINT}/image/${memberId}/${fileName}`;
};

export const getMemberDefaultImagePath = fileName => {
  return `${MEMBER_ENDPOINT}/defaultimage/${fileName}`;
};
