import React from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";

/*const StyledTextField = styled(TextField)`
  label.focused {
    color: green;
  }
  .MuiOutlinedInput-root {
    direction: rtl;
    fieldset {
      border-color: white;
      background-color: rgba(2, 37, 67, 0.5);
    }
    &:hover fieldset {
      border-color: #87ceeb;
    }
    &.Mui-focused fieldset {
      border-color: rgba(0, 51, 96, 1);
    }
  }
`;*/

const StyledTextField = styled(({ dir, ...other }) => <TextField {...other} />)`
  label.focused {
    color: green;
  }
  .MuiOutlinedInput-root {
    direction: ${props => (props.dir ? props.dir : "rtl")};
    fieldset {
      border-color: white;
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:hover fieldset {
      border-color: #87ceeb;
    }
    &.Mui-focused fieldset {
      border-color: rgba(0, 51, 96, 1);
    }
  }
  ${props =>
    props.center &&
    `.MuiOutlinedInput-input {
    text-align: center;
  }`}
`;

export default StyledTextField;
