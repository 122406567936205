import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Navigation from "../containers/navigation";

class Master extends Component {
  render() {
    return (
      <Grid
        container
        style={{
          height: "100%"
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Navigation />
          </Grid>
          <Grid
            item
            style={{
              margin: "16px"
            }}
          >
            {this.props.children}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Master;
