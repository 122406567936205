import { ADMIN_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getAdmin = adminId => {
  return myFetch(`${ADMIN_ENDPOINT}/${adminId}`, "GET");
};

export const getAdmins = () => {
  return myFetch(`${ADMIN_ENDPOINT}`, "GET");
};

export const addAdmin = admin => {
  return admin._id
    ? myFetch(`${ADMIN_ENDPOINT}`, "PUT", {
        admin: admin
      })
    : myFetch(`${ADMIN_ENDPOINT}`, "POST", {
        admin: admin
      });
};

export const changePassword = (adminId, passwordCurrent, password) => {
  return myFetch(`${ADMIN_ENDPOINT}/password`, "PUT", {
    adminId: adminId,
    passwordCurrent: passwordCurrent,
    password: password
  });
};

export const removeAdmin = adminId => {
  return myFetch(`${ADMIN_ENDPOINT}`, "DELETE", {
    adminId: adminId
  });
};

export const authAdmin = (username, password) => {
  return myFetch(`${ADMIN_ENDPOINT}/auth`, "POST", {
    username,
    password
  });
};

export const getAdminACL = () => {
  return myFetch(`${ADMIN_ENDPOINT}/acl`, "GET");
};

export const getACL = () => {
  return myFetch(`${ADMIN_ENDPOINT}/acls`, "GET");
};
