import { createSlice } from "redux-starter-kit";

const aclSlice = createSlice({
  slice: "acl",
  initialState: null,
  reducers: {
    saveACL(state, action) {
      const acl = action.payload;
      return acl;
    }
  }
});

export const { saveACL } = aclSlice.actions;

export default aclSlice.reducer;
