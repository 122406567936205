export const BASE_URL = `https://crypto.hooshdadeh.ir:50001/dashboard_tad1401@/M7uI_5b=`;
export const BASE_URL_OTHERS = [];
export const USER_ENDPOINT = `${BASE_URL}/users`;
export const MEMBER_ENDPOINT = `${BASE_URL}/members`;
export const PERMISSION_ENDPOINT = `${BASE_URL}/permissions`;
export const REPORT_FACE_ENDPOINT = `${BASE_URL}/reportsFace`;
export const REPORT_PLATE_ENDPOINT = `${BASE_URL}/reportsPlate`;
export const SETTINGS_ENDPOINT = `${BASE_URL}/settings`;
export const CAMERAS_ENDPOINT = `${BASE_URL}/cameras`;
export const ADMIN_ENDPOINT = `${BASE_URL}/admins`;
export const STATS_ENDPOINT = `${BASE_URL}/stats`;
export const STATSPWA_ENDPOINT = `${BASE_URL}/statsPWA`;
export const INBOX_ENDPOINT = `${BASE_URL}/inbox`;
export const CONTACTS_ENDPOINT = `${BASE_URL}/contacts`;
export const FAQ_ENDPOINT = `${BASE_URL}/faq`;
export const PURCHASES_ENDPOINT = `${BASE_URL}/purchases`;
export const RATE_ENDPOINT = `${BASE_URL}/rate`;
export const NOTIF_ENDPOINT = `${BASE_URL}/notif`;
export const REPLIES_ENDPOINT = `${BASE_URL}/replies`;
export const REDIS_ENDPOINT = `${BASE_URL}/redis`;
export const TEMPLATE_ANSWER_ENDPOINT = `${BASE_URL}/templateAnswers`;
export const POPULAR_INSTRUMENTS_ENDPOINT = `${BASE_URL}/popularInstruments`;
export const WARRANT_ENDPOINT = `${BASE_URL}/warrants`;
export const CREDITS_ENDPOINT = `${BASE_URL}/credits`;
export const CHECKOUT_ENDPOINT = `${BASE_URL}/creditCheckout`;
export const FILTERS_ENDPOINT = `${BASE_URL}/filters`;
export const STRATEGIES_ENDPOINT = `${BASE_URL}/strategies`;
export const ASSEMBLY_ENDPOINT = `${BASE_URL}/assemblies`;

//export const unknown_conf_max = 0.6;
//export const alarm_conf_min = 0.9;

//export const APP_MODE_FACE = 0;
//export const APP_MODE_PLATE = 1;
//export const APP_MODE = APP_MODE_FACE;

export const APP_MODES = {
  FACE: 0,
  PLATE: 1,
};
export const APP_MODE = APP_MODES.PLATE;
