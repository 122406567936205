export const plateLetters = [
  { persian: "الف", english: "alef", value: 0 },
  { persian: "ب", english: "b", value: 1 },
  { persian: "ج", english: "j", value: 2 },
  { persian: "ل", english: "l", value: 3 },
  { persian: "م", english: "m", value: 4 },
  { persian: "ن", english: "n", value: 5 },
  { persian: "ق", english: "q", value: 6 },
  { persian: "و", english: "v", value: 7 },
  { persian: "ه", english: "h", value: 8 },
  { persian: "ی", english: "y", value: 9 },
  { persian: "د", english: "d", value: 10 },
  { persian: "س", english: "s", value: 11 },
  { persian: "ص", english: "sad", value: 12 },
  { persian: "معلول", english: "malol", value: 13 },
  { persian: "ت", english: "t", value: 14 },
  { persian: "ط", english: "ta", value: 15 },
  { persian: "ع", english: "ein", value: 16 },
  { persian: "غ", english: "ge", value: 17 },
  { persian: "D", english: "diplomat", value: 18 },
  { persian: "S", english: "siyasi", value: 19 },
  { persian: "پ", english: "p", value: 20 },
  { persian: "تشریفات", english: "tashrifat", value: 21 },
  { persian: "ث", english: "the", value: 22 },
  { persian: "ز", english: "ze", value: 23 },
  { persian: "ش", english: "she", value: 24 },
  { persian: "ف", english: "fe", value: 25 },
  { persian: "ک", english: "kaf", value: 26 },
  { persian: "گ", english: "gaf", value: 27 }
];

export const getPlatePersianLetter = englishLetter => {
  return plateLetters.filter(p => p.english === englishLetter)[0].persian;
};

export const getPlatePersianLetterFromValue = value => {
  const letter = plateLetters.filter(p => p.value === value)[0];
  if (!letter) return "";
  return letter.persian;
};

export const getPlateValueFromEnglishLetter = englishLetter => {
  return plateLetters.filter(p => p.english === englishLetter)[0].value;
};

export const getPlateEnglishLetter = value => {
  const letter = plateLetters.filter(p => p.value === value)[0];
  if (!letter) return "";
  return letter.english;
};
