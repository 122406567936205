import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import stylesMembers from "../styles/members";
import { getFaces } from "../services/members";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../helpers/notification";

class MyDropzone extends Component {
  onDrop = files => {
    if (files.length == 0) return;

    var newFile = files[0];
    const reader = new FileReader();
    reader.onload = event => {
      const base64 = event.target.result;
      //console.log(base64);

      if (this.props.getFaces === false) {
        //this.props.onDrop([], URL.createObjectURL(newFile), base64, 1);
        this.setState(
          {
            file: Object.assign(newFile, {
              preview: URL.createObjectURL(newFile),
              base64: base64
            })
          },
          () => {
            this.props.onDrop([], this.state.file.preview, base64, 1);
          }
        );
        return;
      }

      getFaces(base64)
        .then(data => {
          if (!data.result || data.result.length == 0) {
            this.props.onDrop(null, null, null, 0);
          } else if (data.result.length > 1) {
            this.props.onDrop(null, null, null, data.result.length);
          } else {
            this.setState(
              {
                vector: data.result[0].vector,
                file: Object.assign(newFile, {
                  preview: URL.createObjectURL(newFile),
                  base64: base64
                })
              },
              () => {
                this.props.onDrop(
                  data.result[0].vector,
                  this.state.file.preview,
                  base64,
                  1
                );
              }
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    };
    reader.readAsDataURL(newFile);
  };

  componentWillUnmount() {
    if (this.state && this.state.file)
      URL.revokeObjectURL(this.state.file.preview);
  }

  render() {
    const { classes, hasId, getFaces } = this.props;

    return (
      <Dropzone
        onDrop={this.onDrop}
        accept="image/jpeg, image/png"
        multiple={false}
      >
        {/*({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography variant="body2" align="left" gutterBottom>
                عکس را انتخاب کنید یا عکس را اینجا رها کنید...
              </Typography>
            </div>
          )*/}

        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
          rejectedFiles
        }) => {
          let styles = { ...classes.imageDropzone };
          styles = isDragActive
            ? { ...styles, ...classes.imageDropzoneActive }
            : styles;
          styles = isDragReject
            ? { ...styles, ...classes.imageDropzoneReject }
            : styles;

          return !hasId ? (
            <Typography variant="body2" align="center" color="secondary">
              ابتدا عضو جدید را ذخیره کنید سپس تصاویر را بارگذاری نمایید
            </Typography>
          ) : (
            <div {...getRootProps()} className={classes.imageDropzone}>
              <input {...getInputProps()} />
              {/*<div>{isDragAccept ? "Drop" : "Drag"} files here...</div>*/}
              <Typography variant="body2" align="center" color="secondary">
                {this.props.title ||
                  "...تصویر جدید را انتخاب یا اینجا رها کنید"}
              </Typography>
              {isDragReject && (
                <Typography variant="caption" align="right">
                  فایل غیرمجاز...
                </Typography>
              )}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(MyDropzone)
);
