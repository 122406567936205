const styles = {
  replied: {
    color: "white",
    fontSize: "12px",
    backgroundColor: "#980098",
    borderRadius: "4px"
    //overflowWrap: "break-word"
  },
  label: {
    color: "gray",
    fontSize: "12px"
    //overflowWrap: "break-word"
  },
  info: {
    color: "black",
    fontSize: "14px"
    //overflowWrap: "break-word"
  },
  title: {
    color: "gray",
    fontSize: "16px",
    textAlign: "right",
    marginTop: 40,
    marginBottom: 8
  },
  replyGrid: {
    color: "black",
    fontSize: "10px",
    backgroundColor: "#DFF1FE",
    borderRadius: "8px",
    margin: "4px",
    //marginRight: "16px",
    //marginLeft: "32px",
    marginBottom: "4px",
    padding: "4px"
    //overflowWrap: "break-word"
  }
};

export default styles;
