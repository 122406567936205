const styles = {
  memberScroll: {
    height: "100%",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "12px"
    //marginTop: "8px"
  },
  memberScrollNoPadding: {
    height: "100%",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto"
  },
  memberContent: {
    //background: "rgb(2,37,67)",
    background:
      "linear-gradient(0deg, rgba(230,230,230,0.85) 0%, rgba(200,200,200,0.85) 100%)",
    borderRadius: "4px",
    //padding: "2px 16px 8px 16px",
    //marginTop: "12px"
    padding: "2px"
  },
  memberName: {
    color: "black",
    fontSize: "12px"
    //overflowWrap: "break-word"
  },
  memberImage: {
    //border: "2px solid #28618e",
    width: "100%",
    //height: "10vh",
    objectFit: "contain",
    borderRadius: "4px"
  },
  editIcon: {
    cursor: "pointer",
    height: "20px"
    //marginLeft: 8
  },
  new: {
    marginTop: "32px"
  },
  train: {
    marginTop: "32px",
    backgroundColor: "red"
  },
  label: {
    color: "#09006D",
    fontSize: "14px"
  },
  label2: {
    color: "white",
    fontSize: "11px"
  },
  imagePreview: {
    height: "15vh",
    marginTop: "8px",
    border: "1px solid white"
  },
  imageDropzone: {
    borderWidth: 2,
    borderColor: "#666",
    borderStyle: "dashed",
    borderRadius: 5,
    padding: "16px",
    cursor: "pointer",
    marginTop: "16px"
  },
  imageDropzoneActive: {
    borderStyle: "solid",
    borderColor: "#6c6",
    backgroundColor: "#eee"
  },
  imageDropzoneReject: {
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee"
  },
  upload: {
    //background: "green",
    backgroundColor: "green"
  },
  memberEditImages: {
    marginTop: "16px"
  },
  memberEditImage: {
    border: "2px solid #28618e",
    width: "98%",
    height: "10vw",
    objectFit: "contain",
    borderRadius: "4px"
  },
  memberEditImagePlate: {
    border: "2px solid #28618e",
    height: "12vw",
    objectFit: "contain",
    borderRadius: "4px"
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
    height: "24px"
    //marginLeft: 8
  },
  defaultIcon: {
    position: "absolute",
    bottom: "2%",
    left: "0px",
    cursor: "pointer",
    height: "32px"
    //marginLeft: 8
  },
  dateField: {
    //direction: "rtl",
    //borderColor: "white",
    ".MuiOutlinedInput-root": {
      direction: "rtl",
      fieldset: {
        borderColor: "white",
        backgroundColor: "rgba(2, 37, 67, 0.5)"
      },
      "&:hover fieldset": {
        borderColor: "#87ceeb"
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(0, 51, 96, 1)"
      }
    }
  },
  radioGroup: {
    flexDirection: "row-reverse"
  },
  reportClockIcon: {
    height: "14px",
    marginLeft: 4
  },
  reportDirectionIcon: {
    position: "absolute",
    left: 10,
    bottom: 14,
    height: "16px"
  },
  replied: {
    color: "white",
    fontSize: "12px",
    backgroundColor: "#008D00",
    borderRadius: "4px"
    //overflowWrap: "break-word"
  }
};

export default styles;
