import { WARRANT_ENDPOINT, BASE_URL_OTHERS } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const getWarrant = (warrantId) => {
  return myFetch(`${WARRANT_ENDPOINT}/${warrantId}`, "GET");
};

export const getWarrants = () => {
  return myFetch(`${WARRANT_ENDPOINT}`, "GET");
};

export const addWarrant = (warrant) => {
  return warrant._id
    ? myFetch(`${WARRANT_ENDPOINT}`, "PUT", {
        warrant: warrant,
      })
    : myFetch(`${WARRANT_ENDPOINT}`, "POST", {
        warrant: warrant,
      });
};

export const removeWarrant = (warrantId) => {
  return myFetch(`${WARRANT_ENDPOINT}`, "DELETE", {
    warrantId: warrantId,
  });
};

export const clearWarrantsCache = () => {
  /*BASE_URL_OTHERS.forEach((BASE_URL) => {
    myFetch(`${BASE_URL}/warrants/cache/clear`, "GET");
  });*/
  return myFetch(`${WARRANT_ENDPOINT}/cache/clear`, "GET");
};
