import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import stylesMembers from "../styles/members";
import MyButton from "../components/button";
import { InputRow } from "../components/inputRow";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../helpers/notification";
import { Link, Redirect } from "react-router-dom";
import {
  getAssembly,
  addAssembly,
  clearAssembliesCache,
} from "../services/assemblies";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Typography } from "@material-ui/core";

class NewAssembly extends Component {
  state = {};

  componentDidMount = () => {
    if (this.props.match.params.id) {
      var assemblyId = this.props.match.params.id;
      if (!assemblyId) return;
      getAssembly(assemblyId)
        .then((data) => {
          if (data.forbidden === true) {
            showError(this.props, "عملیات غیر مجاز");
            return;
          }
          this.setState({
            edit: true,
            assembly_id: assemblyId,
            assembly: data,
            admin_id: data.admin_id,
            name: data.name,
            date: data.date,
            type: data.type,
            agenda: data.agenda,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /*componentDidUpdate = () => {
    if (this.props.templateAnswer && !this.state.templateAnswer) {
      const templateAnswer = this.props.templateAnswer;
      this.setState({
        edit: true,
        templateAnswer: templateAnswer,
        order: templateAnswer.order,
        title: templateAnswer.title,
        answer: templateAnswer.answer,
      });
    }
  };*/

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSave = () => {
    const edit = this.state.edit;
    const name = this.state.name;
    const date = this.state.date;
    const type = this.state.type;
    const agenda = this.state.agenda;

    if (!name || !date || !type || !agenda) {
      this.setState({
        name_error: !name,
        date_error: !date,
        type_error: !type,
        agenda_error: !agenda,
      });
      return;
    }

    this.setState({
      loading: true,
      name_error: false,
      date_error: false,
      type_error: false,
      agenda_error: false,
    });

    const assembly = {
      name,
      date,
      type,
      agenda,
    };

    if (this.state.edit) {
      assembly._id = this.state.assembly._id;
    }

    addAssembly(assembly)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ذخیره پیش آمد");
          return;
        }
        if (data.error) {
          showError(this.props, "خطا");
          return;
        }

        showInfo(this.props, "مجمع با موفقیت ذخیره گردید");
        if (!this.state.edit && data.id)
          this.setState({
            assembly: { ...assembly, id: data.id },
            edit: true,
          });
        this.setState({
          loading: false,
          saved: true,
        });

        clearAssembliesCache()
          .then((data) => {})
          .catch((error) => {});
      })
      .catch((error) => {
        showError(this.props, "اشکالی در ذخیره پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      date,
      type,
      agenda,
      increase_percent,
      show,
      hide,
      edit,
      name_error,
      date_error,
      type_error,
      agenda_error,
      sending,
      success,
      fail,
    } = this.state;

    if (this.state.saved === true) {
      return <Redirect to="/data" />;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div className={classes.memberScroll}>
        <Grid container direction="column">
          <InputRow
            id="name"
            value={name}
            onChange={this.handleChange}
            label="نماد"
            classes={classes}
            error={name_error}
          />
          <InputRow
            id="date"
            value={date}
            onChange={this.handleChange}
            label="تاریخ مجمع"
            classes={classes}
            error={date_error}
          />
          <InputRow
            id="type"
            value={type}
            onChange={this.handleChange}
            label="نوع مجمع"
            classes={classes}
            error={type_error}
          />
          <InputRow
            id="agenda"
            value={agenda}
            onChange={this.handleChange}
            label="دستور جلسه"
            classes={classes}
            error={agenda_error}
          />

          <Grid
            container
            direction="column"
            spacing={1}
            style={{ marginTop: "16px" }}
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    type: "عادی سالیانه",
                    agenda: "تصویب صورت‌های مالی",
                  });
                }}
              >
                عادی سالیانه (مالی)
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    type: "عادی سالیانه",
                    agenda: "تصویب صورت‌های مالی و انتخاب هیئت مدیره",
                  });
                }}
              >
                عادی سالیانه (مالی و هیئت مدیره)
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    type: "عمومی فوق‌العاده",
                    agenda: "افزایش سرمایه",
                  });
                }}
              >
                عمومی فوق‌العاده (افزایش سرمایه)
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    type: "عادی فوق‌العاده",
                    agenda: "تصویب صورت‌های مالی",
                  });
                }}
              >
                عادی فوق‌العاده (مالی)
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  this.setState({
                    type: "عادی فوق‌العاده",
                    agenda: "انتخاب هیئت مدیره",
                  });
                }}
              >
                عادی فوق‌العاده (هیئت مدیره)
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Link to="/data" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                بازگشت
              </MyButton>
            </Link>
          </Grid>
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
            >
              ذخیره
            </MyButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewAssembly)
);
