import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import MyTextField from "./textField";

export const InputRow = props => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={props.labelXS ? 12 - props.labelXS : 10}>
        <MyTextField
          id={props.id}
          name={props.id}
          className={props.classes.textField}
          value={props.value}
          onChange={props.onChange}
          margin="dense"
          variant="outlined"
          type={props.type ? props.type : "text"}
          error={props.error}
          fullWidth={props.width ? false : true}
          style={props.width ? { width: props.width } : {}}
          dir={props.dir}
          multiline={props.multiline ? true : false}
          rows={props.rows ? props.rows : "1"}
        />
      </Grid>
      <Grid item xs={props.labelXS ? props.labelXS : 2}>
        <Typography align="left" className={props.classes.label}>
          :{props.label}
        </Typography>
      </Grid>
    </Grid>
  );
};
