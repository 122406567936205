import { configureStore } from "redux-starter-kit";
import userReducer from "./reducers/user";
import aclReducer from "./reducers/acl";

const store = configureStore({
  reducer: {
    user: userReducer,
    acl: aclReducer
    //posts: postsReducer
  }
});

export default store;
