import { USER_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const searchUser = (userId, phoneNumber) => {
  return myFetch(`${USER_ENDPOINT}/search/`, "POST", {
    userId,
    phoneNumber
  });
};

/*
export const searchUser = (userId, phoneNumber) => {
  return myFetch(
    `${USER_ENDPOINT}/search?userId=${userId}&phoneNumber=${phoneNumber}`,
    "GET"
  );
};
*/

export const getUser = userId => {
  return myFetch(`${USER_ENDPOINT}/${userId}`, "GET");
};

export const getUsers = () => {
  return myFetch(`${USER_ENDPOINT}`, "GET");
};

export const addUser = user => {
  return user.id
    ? myFetch(`${USER_ENDPOINT}`, "PUT", {
        user: user
      })
    : myFetch(`${USER_ENDPOINT}`, "POST", {
        user: user
      });
};

export const changePassword = (userId, passwordCurrent, password) => {
  return myFetch(`${USER_ENDPOINT}/password`, "PUT", {
    userId: userId,
    passwordCurrent: passwordCurrent,
    password: password
  });
};

export const removeUser = userId => {
  return myFetch(`${USER_ENDPOINT}`, "DELETE", {
    userId: userId
  });
};

export const authUser = (username, password) => {
  return myFetch(`${USER_ENDPOINT}/auth`, "POST", {
    username,
    password
  });
};

export const getUserACL = () => {
  return myFetch(`${USER_ENDPOINT}/acl`, "GET");
};

export const getACL = () => {
  return myFetch(`${USER_ENDPOINT}/acls`, "GET");
};
