import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import LiveContainer from "../containers/live/live";
import BoxTitle from "../components/boxTitle";
import InboxContainer from "../containers/inbox/inboxList";
import { unixToTime } from "../helpers/unix";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getStats } from "../services/stats";
import { getServer, getSettings } from "../services/settings";
import { APP_MODE, APP_MODES } from "../constants/settings";
import { plateLetters, getPlatePersianLetter } from "../constants/plateLetters";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../helpers/notification";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PurchasesContainer from "../containers/report/purchases";
import RepliesContainer from "../containers/report/replies";
import PopularInstrumentsContainer from "../containers/report/popularInstruments";
import CheckoutsContainer from "../containers/filter/checkouts";
import MostBuyFiltersContainer from "../containers/filter/mostBuyFilters";
import MostBuyStrategiesContainer from "../containers/filter/mostBuyStrategies";

class Filter extends Component {
  state = {
    value: 2,
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item>
            <Tabs
              value={value}
              onChange={this.handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="استراتژی‌های پرفروش" />
              <Tab label="فیلترهای پرفروش" />
              <Tab label="تسویه حساب" />
            </Tabs>
          </Grid>
        </Grid>

        <br />

        {value == 2 && <CheckoutsContainer stats={this.state.stats} />}
        {value == 1 && <MostBuyFiltersContainer stats={this.state.stats} />}
        {value == 0 && <MostBuyStrategiesContainer stats={this.state.stats} />}
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(Filter));
