import { CONTACTS_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

//export const getContacts = userId => {
//  return myFetch(`${CONTACTS_ENDPOINT}/${userId}`, "GET");
//};

export const getContacts = (userId, page, pageSize) => {
  return myFetch(`${CONTACTS_ENDPOINT}`, "GET", {
    userId,
    page,
    pageSize
  });
};

export const getContact = contactId => {
  return myFetch(`${CONTACTS_ENDPOINT}/id/${contactId}`, "GET");
};
