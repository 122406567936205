import { REPORT_PLATE_ENDPOINT } from "../constants/settings";
import { myFetch } from "../helpers/myFetch";

export const report = (commonSearchParams, page, pageSize) => {
  return myFetch(`${REPORT_PLATE_ENDPOINT}`, "POST", {
    commonSearchParams: commonSearchParams,
    page: page,
    pageSize: pageSize
  });
};

export const getReportPlateImages = (
  camId,
  epoch,
  plateId,
  requestCarImage
) => {
  var reqCarImage = requestCarImage === true;
  return myFetch(
    `${REPORT_PLATE_ENDPOINT}/image/${camId}/${epoch}/${plateId}/${reqCarImage}`,
    "GET"
  );
};
