import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography, Card, Paper } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { getPurchases } from "../../services/purchases";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { Link } from "react-router-dom";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { withSnackbar } from "notistack";
import { initACL } from "../../helpers/acl";

class Purchases extends Component {
  state = {
    purchases: [],
    user_id: ""
  };

  componentDidMount = async () => {
    this.loadPurchases();
  };

  componentDidUpdate(prevProps, prevState) {
    const result = initACL(this.state.initACL, 0);
    if (result.length > 0) {
      this.setState({
        enableUserInfoButton: result[3],
        initACL: true
      });
    }
  }

  loadPurchases() {
    this.setState({ purchases: [] });
    let user_id = this.state.user_id;
    if (!user_id) user_id = "-1";
    getPurchases(user_id)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ purchases: data.purchases });
      })
      .catch(error => {});
  }

  render() {
    const { classes } = this.props;
    const { purchases, user_id, enableUserInfoButton } = this.state;
    const { cameras, captures, capturesCount, src } = this.props;

    return (
      <Grid container direction="row" /*className={classes.liveContainer}*/>
        {user_id && (
          <img
            src={require(`../../images/icon_delete_image.png`)}
            style={{ width: 32, cursor: "pointer" }}
            onClick={() => {
              this.setState({ user_id: "" }, () => this.loadPurchases());
            }}
            title="حذف فیلتر"
          />
        )}
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="stretch"
          alignContent="stretch"
        >
          {purchases.map((purchase, index) => (
            <Grid key={index} item xs={12}>
              <Card>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  className={classes.memberContent}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-end"
                      xs={12}
                      //xs={7}
                      //sm={8}
                      //md={9}
                      //lg={10}
                      spacing={0}
                      style={{ margin: 4 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="subtitle2"
                        >
                          {dateTimeToPersian(purchase.created_date)}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        {enableUserInfoButton && (
                          <Grid item>
                            <Link
                              to={{
                                pathname: `/user/search`,
                                userId: purchase.user_id
                              }}
                            >
                              <img
                                src={require(`../../images/icon_user.png`)}
                                style={{
                                  width: "18px"
                                }}
                                //className={classes.editIcon}
                                title="اطلاعات کاربر"
                              />
                            </Link>
                          </Grid>
                        )}
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="subtitle2"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "darkblue"
                            }}
                            onClick={() => {
                              this.setState({ user_id: purchase.user_id }, () =>
                                this.loadPurchases()
                              );
                            }}
                          >
                            {purchase.user_id}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="body2"
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {purchase.sku}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="body2"
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {purchase.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="body2"
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {purchase.price}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <img
                      src={require(`../../images/purchase.png`)}
                      className={classes.memberImage}
                      style={{
                        maxWidth: "100%"
                      }}
                      alt=""
                      onError={() => this.noDefaultImage(index)}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Purchases));
