import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import classNames from "classnames";
import styles from "../styles/main";
import BoxTitle from "../components/boxTitle";
import UserContainer from "../containers/user/user";
import { searchUser } from "../services/users";
import { InputRow } from "../components/inputRow";
import MyTextField from "../components/textField";
import MyButton from "../components/button";
import { showInfo, showError } from "../helpers/notification";
import { withSnackbar } from "notistack";

class SearchUser extends Component {
  state = { user: { user: {}, id: "123" } };

  /*componentDidMount = () => {
    if (this.props.match.params.id) {
      var userId = parseInt(this.props.match.params.id);
      if (isNaN(userId)) return;
      getUser(userId)
        .then(data => {
          this.setState({ user: data });
          //console.log("user", data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };*/

  componentDidMount = () => {
    var userId = this.props.location.userId;
    if (userId) {
      this.setState({ id: userId }, () => this.handleSearchUser());
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleEnter = event => {
    if (event.key === "Enter") {
      this.handleSearchUser();
    }
  };

  handleSearchUser = () => {
    const userId = this.state.id;
    const phoneNumber = this.state.phone_number;
    searchUser(userId, phoneNumber)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در جستجوی کاربر پیش آمد");
          return;
        }

        this.setState({
          loading: false,
          user: data
        });
      })
      .catch(error => {
        console.log(error);
        showError(this.props, "اشکالی در جستجوی کاربر پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const { user, id, phone_number } = this.state;

    return (
      <>
        <Grid
          container
          direction="row-reverse"
          alignItems="flex-end"
          spacing={4}
        >
          <Grid item>
            <MyTextField
              name="id"
              value={id || ""}
              label="شناسه کاربر"
              onChange={this.handleChange}
              margin="dense"
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <MyTextField
              name="phone_number"
              value={phone_number}
              label="شماره تلفن"
              onChange={this.handleChange}
              margin="dense"
              //style={{ marginLeft: 32, marginRight: 32 }}
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSearchUser}
            >
              جستجو
            </MyButton>
          </Grid>
        </Grid>
        <div style={{ marginTop: 32 }}>
          <UserContainer user={user} />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbar(SearchUser));
