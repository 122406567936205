import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import MyTextField from "./textField";
import { Typography, Box } from "@material-ui/core";
import classNames from "classnames";
import { SizeMe } from "react-sizeme";
import {
  Input,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader
} from "antd";
import styled from "styled-components";
import {
  plateLetters,
  getPlatePersianLetter,
  getPlateValueFromEnglishLetter
} from "../constants/plateLetters";

const InputGroup = Input.Group;
const Option = Select.Option;

const ValueSelector = props => {
  return (
    <Select
      style={{ width: 30 }}
      showArrow={false}
      size="small"
      dropdownStyle={{ width: 40, fontSize: "8px" }}
    >
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
        <Option value={item}>{item}</Option>
      ))}
    </Select>
  );
};

const ValueInput = props => {
  return <Input placeholder="دو رقم" />;
};

const StyledInput = styled(Input)`
  padding: 0;
  color: black;
`;

const StyledSelect = styled(Select)`
  .ant-select-dropdown {
    width: 100px;
  }
  .ant-select-dropdown-menu {
    width: 100px;
  }
`;

const inputStyles = {
  padding: 0,
  color: "black",
  border: "1px solid #505050"
};

class PlateSelector extends Component {
  constructor(props) {
    super(props);
    this.plate_input = [
      null,
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef()
    ];
  }

  state = {
    plate_numbers: [null, null, null, null, null, null, null],
    plate_letter: null
  };

  componentDidMount = () => {
    this.props.onPlateChange(this.state.plate_numbers, this.state.plate_letter);
  };

  componentDidUpdate = () => {
    if (this.props.default && !this.state.default) {
      const numbers = this.props.default.match(/\d+/g).join("");
      const letter = this.props.default.match(/[a-zA-Z]+/g).join("");
      this.setState(
        {
          plate_numbers: [
            numbers[0],
            numbers[1],
            numbers[2],
            numbers[3],
            numbers[4],
            numbers[5],
            numbers[6]
          ],
          plate_letter: getPlateValueFromEnglishLetter(letter),
          plate_letter_value: getPlatePersianLetter(letter),
          default: this.props.default
        },
        () => {
          this.props.onPlateChange(
            this.state.plate_numbers,
            this.state.plate_letter
          );
        }
      );
    }
  };

  onChange = index => e => {
    const { value } = e.target;
    const reg = /\b[0-9]\b/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      const plate_numbers = this.state.plate_numbers;
      plate_numbers[index] = value;
      this.setState({ plate_numbers });
      if (index + 1 < this.plate_input.length)
        this.plate_input[index + 1].current.focus();

      this.props.onPlateChange(plate_numbers, this.state.plate_letter);
    }
  };

  onChangeLetter = index => {
    const letter = plateLetters[index].value;
    this.props.onPlateChange(this.state.plate_numbers, letter);
    this.setState({
      plate_letter: letter,
      plate_letter_value: plateLetters[index].persian
    });
  };

  handleClear = () => {
    this.setState(
      {
        plate_numbers: [null, null, null, null, null, null, null],
        plate_letter: null,
        plate_letter_value: null
      },
      () => {
        this.props.onPlateChange(
          this.state.plate_numbers,
          this.state.plate_letter
        );
      }
    );
  };

  render() {
    const { classes, plate, height } = this.props;
    const { plate_numbers, plate_letter_value } = this.state;

    return (
      <div className={classes.relative}>
        <img
          src={require(`../images/plate.png`)}
          style={{ width: "100%" }}
          alt=""
        />

        <img
          src={require(`../images/icon_delete_image.png`)}
          className={classes.plateClear}
          onClick={this.handleClear}
          title="پاک کردن پلاک"
        />

        <Row
          gutter={2}
          style={{
            position: "absolute",
            top: "22%",
            left: "10%",
            right: "25%"
          }}
        >
          <Col span={7}>
            <InputGroup compact>
              <Input
                style={{ ...inputStyles, width: "50%" }}
                value={plate_numbers[0]}
                onChange={this.onChange(0)}
              />
              <Input
                style={{ ...inputStyles, width: "50%" }}
                value={plate_numbers[1]}
                onChange={this.onChange(1)}
                ref={this.plate_input[1]}
              />
            </InputGroup>
          </Col>
          <Col span={6}>
            <Select
              showArrow={false}
              dropdownStyle={{
                minWidth: "80px",
                fontSize: "14px",
                color: "black"
              }}
              className={classes.plateLetter}
              onChange={this.onChangeLetter}
              value={plate_letter_value}
            >
              {plateLetters.map((plateLetter, index) => (
                <Option value={index} key={index} style={{ color: "black" }}>
                  {plateLetter.persian}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <InputGroup compact>
              <Input
                style={{ ...inputStyles, width: "33.33%" }}
                value={plate_numbers[2]}
                onChange={this.onChange(2)}
                ref={this.plate_input[2]}
              />
              <Input
                style={{ ...inputStyles, width: "33.33%" }}
                value={plate_numbers[3]}
                onChange={this.onChange(3)}
                ref={this.plate_input[3]}
              />
              <Input
                style={{ ...inputStyles, width: "33.33%" }}
                value={plate_numbers[4]}
                onChange={this.onChange(4)}
                ref={this.plate_input[4]}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row
          gutter={2}
          style={{
            position: "absolute",
            top: "30%",
            left: "79%",
            right: "2%"
          }}
        >
          <Col span={24}>
            <InputGroup compact>
              <Input
                style={{ ...inputStyles, width: "50%" }}
                value={plate_numbers[5]}
                onChange={this.onChange(5)}
                ref={this.plate_input[5]}
              />
              <Input
                style={{ ...inputStyles, width: "50%" }}
                value={plate_numbers[6]}
                onChange={this.onChange(6)}
                ref={this.plate_input[6]}
              />
            </InputGroup>
          </Col>
        </Row>

        {/*<InputGroup compact style={{ position: "absolute", top: 0, right: 0 }}>
          <Input style={{ width: "50%" }} />
          <Input style={{ width: "50%" }} />
    </InputGroup>*/}

        {/*<Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <Grid item>
            <ValueSelector />
          </Grid>
          <Grid item>
            <ValueSelector />
          </Grid>
          <Grid item>
            <InputGroup compact>
              <Input style={{ width: "20%" }} defaultValue="0571" />
              <Input style={{ width: "30%" }} defaultValue="26888888" />
            </InputGroup>
          </Grid>
        </Grid>*/}
      </div>
    );
  }
}

export default withStyles(styles)(PlateSelector);
