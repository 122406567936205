import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesUser from "../../styles/user";
import MyButton from "../../components/button";
import { InputRow } from "../../components/inputRow";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import { Link, Redirect } from "react-router-dom";
import { addUser, changePassword, getACL } from "../../services/users";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import { getCamerasList } from "../../services/cameras";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography, Card, Paper } from "@material-ui/core";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { addRate } from "../../services/rate";

class User extends Component {
  state = { user: { user: {} } };

  componentDidUpdate = () => {
    if (!this.props.user || !this.props.user.user) {
      if (this.state.user)
        this.setState({
          user: null,
          purchases: null,
          contacts: null,
          alarms: null,
          faqs: null,
          rate: null,
          notifications: null,
          watchers: null,
          credits: null,
        });
      return;
    }
    if (
      this.props.user &&
      (!this.state.user || this.props.user.user._id != this.state.user._id)
    ) {
      const user = this.props.user;
      this.setState({
        user: user.user,
        purchases: user.purchases,
        contacts: user.contacts,
        alarms: user.alarms,
        faqs: user.faqs,
        rate: user.rate,
        notifications: user.notifications,
        watchers: user.watchers,
        credits: user.credits,
      });
    }
  };

  addRate(userId) {
    console.log(userId);
    if (!userId) return;

    const rate = {
      secret: "0g9gu7I16h#",
      user_id: userId,
    };

    addRate(rate)
      .then((data) => {
        this.setState({ loading: false });
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در اضافه کردن رای به برنامه پیش آمد");
          return;
        }

        showInfo(this.props, "رای به برنامه با موفقیت اضافه گردید");
      })
      .catch((error) => {
        this.setState({ loading: false });
        showError(this.props, "اشکالی در اضافه کردن رای به برنامه پیش آمد");
      });
  }

  render() {
    const { classes } = this.props;
    const {
      user,
      purchases,
      contacts,
      alarms,
      faqs,
      rate,
      notifications,
      watchers,
      credits,
    } = this.state;

    if (!user) {
      return <Typography variant="h4">!کاربری پیدا نشد</Typography>;
    }

    if (!user.created_date) {
      return <></>;
    }

    return (
      <div>
        <Typography variant="body2" className={classes.title}>
          :اطلاعات
        </Typography>
        <Card>
          <Grid
            item
            container
            direction="column"
            alignItems="flex-end"
            xs={12}
            //xs={7}
            //sm={8}
            //md={9}
            //lg={10}
            spacing={1}
            style={{ padding: 4 }}
          >
            <Grid item>
              <Typography variant="body2" className={classes.label}>
                {user._id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  {dateTimeToPersian(user.created_date)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :تاریخ نصب
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  {user.last_open ? dateTimeToPersian(user.last_open) : "-"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :آخرین باز کردن
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.info}
                  style={{ fontFamily: "Roboto, sans-serif" }}
                >
                  {user.device}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :دستگاه و نسخه
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  {user.phone_number}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :شماره تلفن
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  {user.membership && dateTimeToPersian(user.membership)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :تاریخ اتمام اشتراک
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  تومان
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  {user.credit ? user.credit.toLocaleString() : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :اعتبار
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <img
                  src={require(`../../images/icon_rating.png`)}
                  style={{
                    maxWidth: "24px",
                    cursor: "pointer",
                  }}
                  onDoubleClick={() => this.addRate(user._id)}
                  title="رای به برنامه"
                />
              </Grid>
              {rate && (
                <>
                  <Grid item>
                    <Typography
                      className={classes.memberName}
                      variant="caption"
                    >
                      ({dateTimeToPersian(rate.created_date)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.memberName}
                      variant="caption"
                    >
                      :ارسال شده در)
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography variant="body2" className={classes.info}>
                  {rate && rate.rated_date
                    ? dateTimeToPersian(rate.rated_date)
                    : "---"}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.label}>
                  :آخرین رای به برنامه
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Typography variant="body2" className={classes.title}>
          :خریدها
        </Typography>
        <Grid container direction="row-reverse" spacing={1}>
          {purchases.map((purchase, index) => (
            <Grid key={index} item xs={12} sm={4} md={4} lg={3}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {dateTimeToPersian(purchase.created_date)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :تاریخ خرید
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {purchase.sku}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :sku
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {purchase.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :نوع
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {purchase.price}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :قیمت
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="body2" className={classes.title}>
          :اعتبارها
        </Typography>
        <Grid container direction="row-reverse" spacing={1}>
          {credits.map((credit, index) => (
            <Grid key={index} item xs={12} sm={4} md={3} lg={2}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {dateTimeToPersian(credit.created_date)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :تاریخ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {credit.type}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :نوع
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        تومان
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.info}
                        style={{
                          color: credit.amount < 0 ? "Red" : "#00b300",
                        }}
                      >
                        {credit.amount.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :مبلغ
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="body2" className={classes.title}>
          :ارتباط با ما
        </Typography>
        <Grid
          container
          direction="row-reverse"
          alignItems="stretch"
          spacing={1}
        >
          {contacts.map((contact, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {dateTimeToPersian(contact.created_date)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :تاریخ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      className={classes.info}
                      style={{ textAlign: "right", direction: "rtl" }}
                    >
                      {contact.message}
                    </Typography>
                  </Grid>
                  {contact.reply_inbox_id && (
                    <Grid
                      container
                      direction="column"
                      justify="flex-end"
                      className={classes.replyGrid}
                    >
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            ({contact.supporter_username})
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {contact.supporter_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            :پشتیبان
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="body2"
                          style={{
                            textAlign: "right",
                            direction: "rtl",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                        >
                          {contact.inbox.message}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {contact.inbox.voters_down
                              ? contact.inbox.voters_down.length
                              : 0}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <img
                            style={{
                              width: "16px",
                            }}
                            src={require(`../../images/thumb_down.png`)}
                          />
                        </Grid>

                        <Grid item>
                          <img
                            style={{
                              width: "16px",
                              marginLeft: "16px",
                            }}
                            src={require(`../../images/thumb_up.png`)}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {contact.inbox.voters_up
                              ? contact.inbox.voters_up.length
                              : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="body2" className={classes.title}>
          :هشدارها
        </Typography>
        <Grid
          container
          direction="row-reverse"
          alignItems="stretch"
          spacing={1}
        >
          {alarms.map((alarm, index) => (
            <Grid key={index} item xs={6} sm={4} md={2} lg={1}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {alarm.instrument_name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :نماد
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {alarm.type}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :نوع
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.info}>
                      {alarm.subtype}
                    </Typography>
                  </Grid>
                  {alarm.value && (
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.label}>
                        مساوی با {alarm.value.toLocaleString()}
                      </Typography>
                    </Grid>
                  )}
                  {alarm.value_min && (
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.label}>
                        کوچکتر از {alarm.value_min.toLocaleString()}
                      </Typography>
                    </Grid>
                  )}
                  {alarm.value_max && (
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.label}>
                        بزرگتر از {alarm.value_max.toLocaleString()}
                      </Typography>
                    </Grid>
                  )}
                  {!alarm.value && !alarm.value_min && !alarm.value_max && (
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.label}>
                        ‌
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="body2" className={classes.title}>
          :سوالات متداول
        </Typography>
        <Grid
          container
          direction="row-reverse"
          alignItems="stretch"
          spacing={1}
        >
          {faqs.map((faq, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {dateTimeToPersian(faq.created_date)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :تاریخ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      className={classes.info}
                      style={{ textAlign: "right", direction: "rtl" }}
                    >
                      {faq.question}
                    </Typography>
                  </Grid>
                  {faq.reply_inbox_id && (
                    <Grid
                      container
                      direction="column"
                      justify="flex-end"
                      className={classes.replyGrid}
                    >
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            ({faq.supporter_username})
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {faq.supporter_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            :پشتیبان
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="body2"
                          style={{
                            textAlign: "right",
                            direction: "rtl",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                        >
                          {faq.inbox.message}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {faq.inbox.voters_down
                              ? faq.inbox.voters_down.length
                              : 0}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <img
                            style={{
                              width: "16px",
                            }}
                            src={require(`../../images/thumb_down.png`)}
                          />
                        </Grid>

                        <Grid item>
                          <img
                            style={{
                              width: "16px",
                              marginLeft: "16px",
                            }}
                            src={require(`../../images/thumb_up.png`)}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {faq.inbox.voters_up
                              ? faq.inbox.voters_up.length
                              : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="body2" className={classes.title}>
          :دیده بان
        </Typography>
        <Grid
          container
          direction="row-reverse"
          alignItems="stretch"
          spacing={1}
        >
          {watchers.map((watcher, index) => (
            <Grid key={index} item xs={4} sm={2} md={1} lg={1}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {watcher.instrument_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Typography variant="body2" className={classes.title}>
          :نوتیفیکیشن ها
        </Typography>
        <Grid
          container
          direction="row-reverse"
          alignItems="stretch"
          spacing={1}
        >
          {notifications.map((notification, index) => (
            <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
              <Card>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  //xs={7}
                  //sm={8}
                  //md={9}
                  //lg={10}
                  spacing={1}
                  style={{ padding: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {dateTimeToPersian(notification.created_date)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :تاریخ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {notification.instrument_name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :نماد
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2" className={classes.info}>
                        {notification.signal_name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.label}>
                        :نوع سیگنال
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesUser })(withSnackbar(User));
