import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import { Typography } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";

class Live extends Component {
  state = {
    liveLoaded: false,
    captures: [],
    selectedCameraId: 0,
    rand: ""
  };

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.src !== this.props.src) {
      this.retryLiveLoad();
    }
  };

  componentWillUnmount = () => {
    if (this.state.liveInterval) clearInterval(this.state.liveInterval);
  };

  retryLiveLoad = () => {
    setTimeout(() => {
      if (this.state.liveLoaded) return;
      const liveInterval = setInterval(() => {
        if (this.state.liveLoaded) return;
        this.setState({ rand: `&time=${new Date().getTime()}` });
      }, 5000);
      this.setState({ liveInterval });
    }, 5000);
  };

  handleLiveLoaded = () => {
    this.setState({ liveLoaded: true });
    if (this.state.liveInterval) clearInterval(this.state.liveInterval);
  };

  handleSelectCamera = (camera, index) => {
    this.props.selectCamera(camera);
    this.setState({ selectedCameraId: index, liveLoaded: false });
  };

  openCaptureInfoDialog = captureInfoId =>
    this.setState({ captureInfoDialog: true, captureInfoId: captureInfoId });

  closeCaptureInfoDialog = () =>
    this.setState({ captureInfoDialog: false, captureInfoId: null });

  render() {
    const { classes } = this.props;
    const { liveLoaded, selectedCameraId } = this.state;
    const { cameras, captures, capturesCount, src } = this.props;

    return (
      <>
        <MemberInfoDialog
          open={this.state.captureInfoDialog}
          handleCancel={this.closeCaptureInfoDialog}
          member={this.state.captureInfoId}
        />

        <Grid container direction="row" className={classes.liveContainer}>
          <Grid item container direction="column" xs={10}>
            <Grid item>
              {!liveLoaded && (
                <img
                  src={require(`../../images/live.png`)}
                  className={classes.liveBorder}
                  alt=""
                />
              )}
              <img
                src={`${src}${this.state.rand}`}
                onLoad={this.handleLiveLoaded}
                className={classNames({
                  [classes.liveBorder]: true,
                  [classes.hide]: !liveLoaded
                })}
                alt=""
              />
            </Grid>
            <Grid
              item
              xs
              style={{
                minHeight: 0
              }}
            >
              <div className={classes.boxRelative}>
                <div
                  className={classNames(
                    classes.boxBorder /*, classes.boxFlex*/
                  )}
                >
                  <div className={classes.captureScroll}>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="flex-end"
                      justify="flex-start"
                    >
                      {captures.map((capture, index) => (
                        <Grid
                          item
                          xs={2}
                          key={index}
                          className={classes.captureItem}
                        >
                          <div>
                            <div className={classes.relative}>
                              <img
                                src={capture.image}
                                className={classNames({
                                  [classes.captureBorder]: true,
                                  [classes.captureImage]: true,
                                  [classes.captureBorderGreen]:
                                    capture.permission == true,
                                  [classes.captureBorderRed]:
                                    capture.permission == false
                                })}
                                alt=""
                                onClick={() =>
                                  this.openCaptureInfoDialog(capture)
                                }
                              />
                              {APP_MODE === APP_MODES.FACE &&
                                capture.gender && (
                                  <img
                                    src={require(`../../images/icon_${
                                      capture.gender === 0 ? "female" : "male"
                                    }.png`)}
                                    className={classes.captureGender}
                                    title="جنسیت"
                                  />
                                )}
                              {APP_MODE === APP_MODES.FACE && capture.age && (
                                <Typography className={classes.captureAge}>
                                  {capture.age}
                                </Typography>
                              )}
                              {APP_MODE === APP_MODES.PLATE &&
                                capture.direction != null && (
                                  <img
                                    src={
                                      capture.direction === "coming"
                                        ? require(`../../images/icon_enter.png`)
                                        : require(`../../images/icon_exit.png`)
                                    }
                                    className={classes.captureGender}
                                    alt=""
                                    title={
                                      capture.direction === "coming"
                                        ? "ورود"
                                        : "خروج"
                                    }
                                  />
                                )}
                              {APP_MODE === APP_MODES.PLATE &&
                                capture.history_count >= 0 && (
                                  <Typography className={classes.captureAge}>
                                    {capture.history_count}
                                  </Typography>
                                )}
                            </div>
                            <Typography
                              variant="caption"
                              component="p"
                              className={classes.captureTime}
                            >
                              {unixToTime(capture.time)}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="p"
                              className={classes.captureName}
                            >
                              {capture.name}
                            </Typography>
                            {APP_MODE == APP_MODES.FACE && (
                              <Typography
                                variant="caption"
                                component="p"
                                className={classes.captureTime}
                              >
                                %{(capture.confidence * 100).toFixed(2)}
                              </Typography>
                            )}
                            {APP_MODE == APP_MODES.PLATE && capture.plate && (
                              <div className={classes.capturePlate}>
                                <Typography>{capture.plate.first}</Typography>
                                <Typography>{capture.plate.letter}</Typography>
                                <Typography>{capture.plate.second}</Typography>
                                <Typography>
                                  -{capture.plate.city_code}
                                </Typography>
                              </div>
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
                <BoxTitle
                  title="شناسایی شده"
                  icon="icon_capture_white"
                  infoLabel=":تعداد شناسایی"
                  infoCount={capturesCount}
                  hideMore
                />
              </div>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2} alignItems="flex-end">
            {cameras &&
              cameras.map((camera, index) => (
                <Grid item key={index}>
                  <Button
                    variant="text"
                    className={classNames({
                      [classes.camera]: true,
                      [classes.cameraSelected]: index == selectedCameraId
                    })}
                    onClick={() => this.handleSelectCamera(camera, index)}
                  >
                    {`${camera.name} ⮜`}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Live);
